import { onValue, ref } from 'firebase/database'
import { db } from '../firebase/firebaseConfig'

export const getSpecialName = (key: string) => {
  let name = ''

  onValue(ref(db, 'specials/' + key), (snap) => {
    if (snap.val()) {
      name = snap.val().title
    }
  })

  return name
}
