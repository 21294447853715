import { Button, Checkbox, Divider, Form, message, Modal, Select } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { onValue, ref, update } from 'firebase/database'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../firebase/firebaseConfig'

interface Props {
  closeModal: () => void
  visible: boolean
  idMember: string
  accessValues: string[]
}
const localData = localStorage.getItem('userData')
export const RolesMembers = (props: Props) => {
  const { closeModal, visible, idMember, accessValues } = props
  const [accessRights, setAccessRights] = useState<CheckboxValueType[]>()
  const [validate, setValidate] = useState('')
  const [form] = Form.useForm()

  useEffect(() => {
    const data = localData ? JSON.parse(localData) : ''

    if (data.access.includes('members')) {
      setValidate('members')
    }
  })
  useEffect(() => {
    form.setFieldsValue({
      access: accessValues,
    })
  }, [visible, accessValues])

  //những quyền truy cập
  const changeAccess = (checkedValue: CheckboxValueType[]) => {
    setAccessRights(checkedValue)
  }

  const handelUpdateRoles = () => {
    if (validate === 'members') {
      idMember &&
        update(ref(db, 'members/' + idMember), {
          access: accessRights,
          validate: validate,
        }).then(() => {
          handleCloseModal()
          Modal.success({
            title: 'Thành công',
            content: 'Cập nhật quyền truy cập thành công',
          })
        })
    } else {
      Modal.warning({
        title: 'Lỗi',
        content: 'Bạn không đủ quyền để thực hiện chức năng này',
      })
    }
  }

  const handleCloseModal = () => {
    setAccessRights([])
    form.resetFields()
    closeModal()
  }

  const access = [
    { value: 'categories', label: 'Chuyên mục' },
    { value: 'members', label: 'Thành viên' },
    { value: 'users', label: 'Người dùng' },
    { value: 'settings', label: 'Hệ thống' },
    { value: 'posts', label: 'Bài viết' },
    { value: 'schedules', label: 'Lịch hẹn' },
    { value: 'pages', label: 'Trang' },
    { value: 'comments', label: 'Bình luận' },
  ]

  return (
    <Modal
      onCancel={handleCloseModal}
      title="Phân quyền"
      visible={visible}
      cancelText="Hủy"
      onOk={handelUpdateRoles}
    >
      Chọn những chuyên mục thành viên được phép quản lý{' '}
      <i className="text-muted">(Thêm, Xóa, Sửa đổi nội dung)</i>
      <Form form={form} onFinish={handelUpdateRoles}>
        <Form.Item name="access">
          <Checkbox.Group className="checkbox-group" onChange={changeAccess}>
            {access.map((item) => (
              <Checkbox key={access.indexOf(item)} value={item.value}>
                {item.label}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
}
