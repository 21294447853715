import { Form, message, Modal, Space } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { push, ref, remove, update } from 'firebase/database'
import { db } from '../firebase/firebaseConfig'
import { Schedule } from '../models'

interface Props {
  schedule?: Schedule
  onCancel: () => void
  visible: boolean
}

export const CancelScheduleModal = (props: Props) => {
  const { visible, onCancel, schedule } = props

  const [form] = Form.useForm()

  const handleCancelSchedule = (values: any) => {
    removeSchedule(values.reason)
  }

  const removeSchedule = (reason?: string) => {
    //remove schedule
    schedule &&
      update(ref(db, 'schedules/' + schedule.key), {
        state: 'canceled',
        reason: reason ?? '',
      }).then(() => {
        //send notification to doctor
        push(ref(db, 'members/' + schedule.idDoctor + '/alerts'), {
          title: 'Hủy lịch hẹn',
          content: 'Lịch hẹn tư vấn của bạn đã bị hủy bỏ bởi quản trị viên',
          id: schedule.key,
          type: 'schedules',
          read: false,
        })

        message.success('Lịch hẹn của bạn đã được hủy')
        closeModal()
      })
  }

  const closeModal = () => {
    form.resetFields()
    onCancel()
  }

  return (
    <Modal visible={visible} onCancel={closeModal} footer={null}>
      Nhằm cải thiện và nâng cao chất lượng dịch vụ, bạn vui lòng cho biết lý do
      khiến bạn hủy lịch hẹn này, xin cám ơn!
      <Form form={form} onFinish={handleCancelSchedule} layout="vertical">
        <Form.Item name="reason" label="Lý do hủy lịch hẹn">
          <TextArea
            maxLength={100}
            showCount
            rows={5}
            placeholder="eg. Đổi ngày khám"
          />
        </Form.Item>
      </Form>
      <div className="text-right">
        <Space>
          <button
            onClick={() => form.submit()}
            className="btn btn-sm btn-success"
          >
            Gửi
          </button>
        </Space>
      </div>
    </Modal>
  )
}
