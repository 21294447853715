import { onValue, ref } from 'firebase/database'
import { db } from '../firebase/firebaseConfig'
import { Member } from '../models'

export const getDoctorName = (key: string) => {
  let name

  onValue(ref(db, 'members/' + key), (snap) => {
    if (snap.val()) {
      name = snap.val().displayName
    }
  })
  return name
}
