import { Card, Form, Input, List, Skeleton, Table, Tabs, Tag } from 'antd'
import { ContentHeader } from '../components/ContentHeader'
import { useEffect, useState } from 'react'
import { Post } from '../models'
import { TablePost } from '../components/TablePost'
import { Link } from 'react-router-dom'
import { getDatabase, onValue, ref } from 'firebase/database'
import { replacename } from '../utils/replace_name'

const { TabPane } = Tabs
const db = getDatabase()

export const Posts = () => {
  const [posts, setPosts] = useState<Post[]>([])
  const [publishs, setPublishs] = useState<Post[]>([])
  const [drafts, setDrafts] = useState<Post[]>([])
  const [bins, setBins] = useState<Post[]>([])
  const [resultSearch, setResultSearch] = useState<Post[]>([])

  useEffect(() => {
    onValue(ref(db, 'posts/'), (snap) => {
      let items: Post[] = []
      if (snap.val()) {
        snap.forEach((item) => {
          items.unshift({
            ...item.val(),
            key: item.key,
          })

          setPosts(items)

          const pubs: Post[] = []
          const dra: Post[] = []
          const bi: Post[] = []

          items.forEach((item) => {
            switch (item.state) {
              case 'Xuất bản':
                pubs.push(item)
                break
              case 'Bản nháp':
                dra.push(item)
                break
              case 'Đã xóa':
                bi.push(item)
                break
              default:
                break
            }
          })
          setPublishs(pubs)
          setDrafts(dra)
          setBins(bi)
        })
      }
    })
  }, [])

  const handleSearch = (key: string) => {
    //Xử lý chuyện tìm kiếm bài viết
    //console.log(replacename(key))
    const items: Post[] = []

    posts.forEach((item) => {
      if (item.slug.includes(replacename(key))) {
        items.push(item)
      }
    })
    setResultSearch(items)
  }

  return (
    <div>
      <ContentHeader title="Bài viết" />
      <div className="container-fluid">
        <Card
          title={
            <Input
              onChange={(e) => handleSearch(e.target.value)}
              prefix={<i className="fas fa-search"></i>}
              style={{ maxWidth: '50%' }}
              placeholder="Tìm kiếm..."
            />
          }
          extra={
            <Link to="/bai-moi/posts/new" className="btn btn-sm btn-success">
              Viết bài mới
            </Link>
          }
        >
          {resultSearch.length > 0 ? (
            <>
              <TablePost data={resultSearch} />
            </>
          ) : (
            <Tabs>
              <TabPane tab={'Tất cả (' + posts.length + ')'} key="all">
                <TablePost data={posts} />
              </TabPane>
              <TabPane tab={'Đã đăng (' + publishs.length + ')'} key="publish">
                <TablePost data={publishs} />
              </TabPane>
              <TabPane tab={'Bản nháp (' + drafts.length + ')'} key="draft">
                <TablePost data={drafts} />
              </TabPane>
              <TabPane tab={'Thùng rác (' + bins.length + ')'} key="bin">
                <TablePost data={bins} />
              </TabPane>
            </Tabs>
          )}
        </Card>
      </div>
    </div>
  )
}
