export const add0toNum = (num: number) => {
  if (num < 10) {
    return '0' + num
  } else {
    return num.toString()
  }
}

export const getDateString = (time?: number) => {
  const date = new Date(time ? time : 0)

  let strDate =
    add0toNum(date.getDate()) +
    '/' +
    add0toNum(date.getMonth() + 1) +
    '/' +
    date.getFullYear()
  return strDate
}
