import { Button, message, Modal, Popover, Table, Tag } from 'antd'
import { ColumnGroupType, ColumnsType } from 'antd/lib/table'
import {
  equalTo,
  onValue,
  orderByChild,
  query,
  ref,
  remove,
  update,
} from 'firebase/database'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { db } from '../firebase/firebaseConfig'
import { CancelScheduleModal } from '../modals/cancel_schedule_modal'
import { SwitchDoctorModal } from '../modals/switch_doctor'
import { Member, Schedule, User } from '../models'
import { changeFormatDate } from '../utils/change_format_date'
import { getDoctorName, getPatientName, getSpecialName } from '../utils'
import { PrescriptionModal } from '../modals/prescription'

interface Props {
  target?: string
}

export const Schedules = (props: Props) => {
  const [schedules, setSchedules] = useState<Schedule[]>([])
  const [isVisibleModalCancel, setIsVisibleModalCancel] = useState(false)
  const [isVisibleModalSwitchDoctor, setIsVisibleModalSwitchDoctor] = useState(
    false,
  )
  const [schedule, setSchedule] = useState<Schedule>()
  const [doctors, setDoctors] = useState<Member[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [isPrescription, setIsPrescription] = useState(false)

  const { id } = useParams()
  const { target } = props

  let filterWith = target == 'user' ? 'idPatient' : 'idDoctor'

  useEffect(() => {
    getSchedulesWithId()
  }, [id, filterWith])

  useEffect(() => {
    getUserMember()
  }, [])

  const getUserMember = () => {
    const itemUsers: User[] = []
    const itemMembers: Member[] = []

    onValue(ref(db, 'members/'), (snap) => {
      if (snap.val()) {
        snap.forEach((item) => {
          itemMembers.push({
            ...item.val(),
            key: item.key,
          })
        })

        setDoctors(itemMembers)
      }
    })

    onValue(ref(db, 'users/'), (snap) => {
      if (snap.val()) {
        snap.forEach((item) => {
          itemUsers.push({
            ...item.val(),
            key: item.key,
          })
        })

        setUsers(itemUsers)
      }
    })
  }
  //get schedules for user

  const closePrecription = () => {
    setIsPrescription(false)
  }

  const getSchedulesWithId = () => {
    let filterSchedule: any
    if (id && filterWith) {
      filterSchedule = query(
        ref(db, 'schedules/'),
        orderByChild(filterWith),
        equalTo(id),
      )
    } else {
      filterSchedule = query(ref(db, 'schedules/'))
    }

    onValue(filterSchedule, (snap) => {
      const items: Schedule[] = []
      if (snap.val()) {
        snap.forEach((item) => {
          items.unshift({
            ...item.val(),
            key: item.key,
          })
        })

        setSchedules(items)
      } else {
        console.log('not correct')
      }
    })
  }

  const d = new Date()
  const day = d.getDate()
  const month = d.getMonth() + 1
  const year = d.getFullYear()

  //check schedule out of date
  useEffect(() => {
    schedules.forEach((schedule) => {
      const time = schedule.attime

      const daySche = parseInt(time.split('-')[2])
      const monSche = parseInt(time.split('-')[1])
      const yearSche = parseInt(time.split('-')[0])

      if (yearSche < year) {
        updateScheduleToOutOfDate(schedule.key)
      } else {
        if (monSche < month) {
          updateScheduleToOutOfDate(schedule.key)
        } else {
          if (daySche < day) {
            updateScheduleToOutOfDate(schedule.key)
          }
        }
      }
    })
  }, [])

  const updateScheduleToOutOfDate = (key?: string) => {
    update(ref(db, 'schedules/' + key), { state: 'outOfDate' })
  }

  const handleCancelSchedule = (item?: Schedule) => {
    item && setSchedule(item)
    setIsVisibleModalCancel(true)
  }

  const showModalSwitch = (item?: Schedule) => {
    item && setSchedule(item)
    setIsVisibleModalSwitchDoctor(true)
  }

  const closeModal = () => {
    setIsVisibleModalCancel(false)
    setSchedule(undefined)
  }

  const handleRemoveSchedule = (key?: string) => {
    remove(ref(db, 'schedules/' + key)).then(() => {
      message.success('Đã xóa')
    })
  }

  const closeModalSwitch = () => {
    setSchedule(undefined)
    setIsVisibleModalSwitchDoctor(false)
  }

  const changeTagName = (item: Schedule) => {
    let color = ''
    let state = ''

    switch (item.state) {
      case 'outOfDate':
        color = 'default'
        state = 'Quá hạn'
        break
      case 'completed':
        color = '#2ecc71'
        state = 'Hoàn thành'
        break
      case 'canceled':
        color = '#f50'
        state = 'Đã hủy'
        break
      default:
        color = '#f39c12'
        state = 'Chờ khám'
        break
    }

    return (
      <div className="text-center">
        <Tag color={color}>{state}</Tag>

        <Popover content={item.reason ? item.reason : ''}>
          {item.reason ? (
            <i className="far fa-question-circle text-muted"></i>
          ) : null}
        </Popover>
      </div>
    )
  }

  const handleComplete = (item: Schedule) => {
    //Mở phiên khám
    setSchedule(item)
    setIsPrescription(true)
  }

  const filterUser: { text: string; value: string }[] = []
  users.forEach((item) => {
    filterUser.push({
      text: item.displayName ? item.displayName : '',
      value: item.uid ? item.uid : '',
    })
  })

  const filterDoctor: { text: string; value: string }[] = []
  doctors.forEach((item) => {
    filterDoctor.push({
      text: item.displayName ? item.displayName : '',
      value: item.key ? item.key : '',
    })
  })

  const columns: ColumnsType<Schedule> = [
    {
      title: 'Ngày hẹn',
      dataIndex: 'attime',
      key: 'attime',
      render: (date: string) => changeFormatDate(date),
    },

    {
      title: 'Chuyên khoa',
      dataIndex: 'special',
      key: 'chuyenkhoa',
      render: (key: string) => getSpecialName(key),
    },

    {
      title: 'Trạng thái',
      dataIndex: '',
      key: 'state',
      render: (item: Schedule) => changeTagName(item),
      filters: [
        { text: 'Chờ khám', value: 'waiting' },
        { text: 'Hoàn thành', value: 'completed' },
        { text: 'Hủy bỏ', value: 'canceled' },
      ],
      onFilter: (value: any, record) => record.state.startsWith(value),
    },
    {
      title: '',
      dataIndex: '',
      key: 'btn',
      render: (item: Schedule) =>
        item.state === 'waiting' ? (
          <>
            <Popover content="Tạo phiên khám">
              <Button
                onClick={() => handleComplete(item)}
                type="text"
                icon={<i className="fas fa-file-prescription text-primary"></i>}
              />
            </Popover>
            <Popover content="Đổi bác sĩ">
              <Button
                onClick={() => showModalSwitch(item)}
                type="text"
                icon={<i className="fas fa-people-arrows text-success"></i>}
              />
            </Popover>
            <Popover content="Hủy lịch hẹn">
              <Button
                onClick={() => handleCancelSchedule(item)}
                type="text"
                icon={<i className="fas fa-times text-danger"></i>}
              ></Button>
            </Popover>
          </>
        ) : (
          <Button
            onClick={() => handleRemoveSchedule(item.key)}
            type="text"
            icon={<i className="fas fa-trash-alt text-muted"></i>}
          />
        ),
    },
  ]

  if (id) {
    if (filterWith == 'idDoctor') {
      columns.unshift({
        title: 'Bệnh nhân',
        dataIndex: '',
        key: 'patient',
        render: (item: Schedule) => (
          <Link
            to={'/thong-tin-nguoi-dung/' + item.idPatient}
            className="text-capitalize"
            style={{ margin: 0 }}
          >
            {getPatientName(item.idPatient)}
          </Link>
        ),
      })
    } else {
      columns.unshift({
        title: 'Bác sĩ',
        dataIndex: '',
        key: 'doctor',
        render: (item: Schedule) => (
          <>
            <Link
              to={'/thanh-vien/info/' + item.idDoctor}
              className="text-capitalize"
              style={{ margin: 0 }}
            >
              {getDoctorName(item.idDoctor)}
            </Link>
          </>
        ),
      })
    }
  } else {
    columns.unshift(
      {
        title: 'Bệnh nhân',
        dataIndex: '',
        key: 'patient',
        render: (item: Schedule) => (
          <Link
            to={'/thong-tin-nguoi-dung/' + item.idPatient}
            className="text-capitalize"
            style={{ margin: 0 }}
          >
            {getPatientName(item.idPatient)}
          </Link>
        ),
      },
      {
        title: 'Bác sĩ',
        dataIndex: '',
        key: 'doctor',
        render: (item: Schedule) => (
          <>
            <Link
              to={'/thanh-vien/info/' + item.idDoctor}
              className="text-capitalize"
              style={{ margin: 0 }}
            >
              {getDoctorName(item.idDoctor)}
            </Link>
          </>
        ),
      },
    )
  }

  return (
    <>
      <Table dataSource={schedules} columns={columns} />
      <CancelScheduleModal
        onCancel={closeModal}
        visible={isVisibleModalCancel}
        schedule={schedule}
      />

      <SwitchDoctorModal
        visible={isVisibleModalSwitchDoctor}
        onCancel={closeModalSwitch}
        schedule={schedule}
      />

      <PrescriptionModal
        visible={isPrescription}
        closeModal={closePrecription}
        schedule={schedule}
        getSchedules={getSchedulesWithId}
      />
    </>
  )
}
