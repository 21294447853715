import {
  Affix,
  Badge,
  Button,
  Card,
  Dropdown,
  Layout,
  List,
  Menu,
  message,
  Popover,
} from 'antd'
import { onValue, ref, update } from 'firebase/database'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { db } from '../firebase/firebaseConfig'
import { ChangePass } from '../modals/Index'
import { Member } from '../models'
import { Alert } from '../models/alert'

const { Header } = Layout

export const HeaderComponent = () => {
  const [member, setMember] = useState<Member>()
  const [isVisibleModalChangePass, setIsVisibleModalChangePass] = useState(
    false,
  )
  const [alerts, setAlerts] = useState<Alert[]>([])
  const navigate = useNavigate()

  let info: any = localStorage.getItem('userData')

  useEffect(() => {
    getMember()
  }, [info])

  const memberInfo = JSON.parse(info)

  const getMember = () => {
    info &&
      onValue(ref(db, 'members/' + memberInfo.username), (snap) => {
        if (snap.val()) {
          setMember(snap.val())
        } else {
          console.log('Không có')
        }
      })
  }

  useEffect(() => {
    const items: Alert[] = []

    onValue(ref(db, 'members/' + member?.userName + '/alerts'), (snap) => {
      snap.forEach((item) => {
        if (!item.val().read) {
          items.unshift({
            ...item.val(),
            key: item.key,
          })
        }

        setAlerts(items)
      })
    })
  }, [member])

  const handleLogout = () => {
    localStorage.clear()
    navigate('/')

    window.location.reload()
  }

  const handleChangePass = () => {
    setIsVisibleModalChangePass(true)
  }

  const dropdownMenus = (
    <Menu
      items={[
        {
          key: 'info',
          label: (
            <Link to={'thanh-vien/info/' + memberInfo.username}>
              <Button type="text">Thông tin tài khoản</Button>
            </Link>
          ),
        },
        {
          key: 'changePass',
          label: (
            <Button type="text" onClick={handleChangePass}>
              Đổi mật khẩu
            </Button>
          ),
        },
        {
          key: 'logout',
          label: (
            <Button type="text" onClick={handleLogout}>
              Đăng xuất
            </Button>
          ),
        },
      ]}
    />
  )

  const closeModal = () => {
    setIsVisibleModalChangePass(false)
  }

  const checkReaded = (key?: string) => {
    update(ref(db, 'members/' + member?.userName + '/alerts/' + key), {
      read: true,
    }).then(() => console.log('Done'))
  }

  //update all alerts to read
  const handleRealAllAlerts = () => {
    alerts.forEach((item) => {
      update(ref(db, 'members/' + member?.userName + '/alerts/' + item.key), {
        read: true,
        alerts: 'members',
      })
    })
  }

  const AlertsModal = () => {
    return (
      <Card
        style={{
          padding: 10,
          minWidth: 500,
          maxHeight: 500,
          overflow: 'scroll',
        }}
      >
        {alerts.length > 0 ? (
          <div className="text-right">
            Đánh dấu tất cả là đã đọc
            <button
              onClick={handleRealAllAlerts}
              className="btn btn-sm btn-light"
            >
              <i className="fas fa-check-double text-muted"></i>
            </button>
          </div>
        ) : null}

        <List
          dataSource={alerts}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  onClick={() => checkReaded(item.key)}
                  type="text"
                  icon={<i className="fas fa-check text-muted"></i>}
                />,
              ]}
            >
              <List.Item.Meta
                title={
                  <Link to={'/' + item.type + '/' + item.key}>
                    {item.title}
                  </Link>
                }
                description={item.content}
              />
            </List.Item>
          )}
        />
      </Card>
    )
  }

  return (
    <>
      <Header
        style={{
          padding: '0 10px',
          backgroundColor: '#e9e9e9',
        }}
      >
        <div className="col text-right justify-center">
          <Dropdown overlay={AlertsModal} placement="topRight">
            <Button
              type="text"
              icon={
                <Badge count={alerts.length} size="small">
                  <i className="fas fa-bell"></i>
                </Badge>
              }
            />
          </Dropdown>

          <Dropdown overlay={dropdownMenus} placement="bottom">
            <Button type="text" style={{ textTransform: 'capitalize' }}>
              {member?.displayName}
            </Button>
          </Dropdown>
        </div>
      </Header>
      <ChangePass
        isVisible={isVisibleModalChangePass}
        closeModal={closeModal}
        member={member}
      />
    </>
  )
}
