import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Progress,
  Row,
  Space,
  Rate,
  Divider,
  Modal,
  Popover,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useState, useEffect, useRef } from 'react'
import { ContentHeader } from '../components/Index'
import { Editor } from '@tinymce/tinymce-react'
import { styles } from '../styles'
import { acceptedImage } from '../utils/accepted_image'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'
import { push, getDatabase, onValue, update } from 'firebase/database'
import * as databse from 'firebase/database'
import { useNavigate, useParams } from 'react-router-dom'
import { replacename } from '../utils/replace_name'
import { defaultImageLink } from '../utils/default_image_link'

const storage = getStorage()
const db = getDatabase()
const refDb = databse.ref

export const NewPost = () => {
  const [form] = useForm()
  const [content, setContent] = useState('')
  const [thumbnailURL, setThumbnailURL] = useState('')
  const [categoriesSeleted, setCategoriesSeleted] = useState([])
  const [categories, setCategories] = useState([])
  const [loaddingPublish, setLoaddingPublish] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(0)
  const [state, setState] = useState('')
  const [author, setAuthor] = useState({})
  const [postKey, setPostKey] = useState('')
  const [target, setTarget] = useState('')
  const [isReview, setIsReview] = useState(false)
  const [star, setStar] = useState(0)
  const [imageURL, setImageURL] = useState('')

  const navigate = useNavigate()
  const { id, path } = useParams()
  const editorRef = useRef(null)

  useEffect(() => {
    if (categoriesSeleted.includes('-N3yWK0CcYyP2-eGxrK_')) {
      setIsReview(true)
    } else {
      setIsReview(false)
    }
  }, [categoriesSeleted])

  useEffect(() => {
    id && setPostKey(id)
    path && setTarget(path)
  }, [id, path])

  const userData = window.localStorage.getItem('userData')
  const infoMember = userData && JSON.parse(userData)
  const [validate, setValidate] = useState('')

  useEffect(() => {
    if (infoMember && infoMember.access.includes('pages')) {
      setValidate('pages')
    }
  }, [])

  useEffect(() => {
    getMember()
    getCategories()
  }, [])

  const getMember = () => {
    infoMember &&
      onValue(refDb(db, 'members/' + infoMember.username), (snap) => {
        if (snap.val()) {
          setAuthor({ ...snap.val(), key: snap.key })
        }
      })
  }

  const getCategories = () => {
    onValue(refDb(db, 'categories/'), (snap) => {
      const cats = []
      if (snap.val()) {
        snap.forEach((item) => {
          cats.push({
            ...item.val(),
            key: item.key,
          })
        })

        setCategories(cats)
      } else {
        message.info('Chưa có chuyên mục nào cả')
      }
    })
  }

  useEffect(() => {
    id &&
      id !== 'new' &&
      onValue(refDb(db, path + '/' + id), (snap) => {
        snap.val() && form.setFieldsValue(snap.val())
        if (snap.val.tags) {
          console.log(snap.val().tags)
        }
        setContent(snap.val().content)
        setThumbnailURL(snap.val().thumbnail)
      })
  }, [id, path])

  const date = new Date()

  const onSubmit = (values) => {
    let content
    if (editorRef) {
      content = editorRef.current.getContent()
    }
    let tags =
      values.tags && values.tags.includes(',') ? values.tags.split(', ') : ''

    if (target !== 'pages' && categoriesSeleted.length == 0) {
      Modal.error({
        title: 'Lỗi',
        content: 'Vui lòng chọn 1 chuyên mục cho bài viết',
      })
    } else {
      //Lấy từ khóa
      const post = {
        title: values.title,
        slug: replacename(values.title),
        description: values.description ?? '',
        content: content,
        author: author.key,
        categories: categoriesSeleted.length > 0 ? categoriesSeleted : '',
        publishDate: date.getTime(),
        thumbnail: thumbnailURL,
        tags: tags,
        state: state,
        view: 0,
        comment: [],
        like: 0,
        isFavoris: values.isFavoris,
        isReview: isReview,
        star: star,
        location: values.location ?? '',
        phone: values.phone ?? '',
        openTime: values.openTime ?? '',
        validate: validate,
      }

      if (validate !== 'pages') {
        Modal.warning({
          title: 'Lỗi',
          content: 'Bạn không đủ quyền để thực hiện chức năng này',
        })
      } else {
        setLoaddingPublish(true)
        if (postKey !== 'new') {
          update(refDb(db, target + '/' + id), post).then(() => {
            console.log('Done')
            setLoaddingPublish(false)
            //Chuyển về trang quản lý bài viết
            message.info('Update successfuly')
            navigate(target == 'posts' ? '/bai-viet' : '/trang')
          })
        } else {
          push(refDb(db, target), post).then(() => {
            console.log('Done')
            setLoaddingPublish(false)
            //Chuyển về trang quản lý bài viết
            message.info('Xuất bản thành công')
            navigate(target == 'posts' ? '/bai-viet' : '/trang')
          })
        }
      }
    }
  }

  // const handleChangeEditor = (e, editor) => {
  //   // const data = editor.currentRef.getContent()
  //   // setContent(data)
  //   //Change to CKEditor
  //   const data = editor.getData()
  //   //setContent(data)
  // }

  const setCategory = (checked) => {
    setCategoriesSeleted(checked)
  }

  const handleImage = (file, target) => {
    const uploadTask = uploadBytesResumable(
      ref(storage, 'images/' + file.name),
      file,
    )
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        setLoadingUpload(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
        console.log(loadingUpload)
      },
      (error) => {
        console.log(error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (target === 'thumbnail') {
            setThumbnailURL(downloadURL)
          } else {
            setImageURL(downloadURL)
          }
        })
      },
    )
  }

  const handleRemovePost = () => {
    if (window.confirm('Bạn muốn hủy bài đăng này')) {
      message.info('Hủy bài đăng, xóa bài đăng')
    }
  }

  const handleSaveDraft = () => {
    setState('Bản nháp')
    form.submit()
  }

  const handleSubmit = () => {
    setState('Xuất bản')
    form.submit()
  }

  const handleCopyImageURL = () => {
    if (imageURL === '') {
      Modal.warning({
        title: 'Warning',
        content: 'Không phát hiện Url hình ảnh, hãy tải ảnh lên trước',
      })
    } else {
      navigator.clipboard.writeText(imageURL)
    }
  }

  return (
    <div>
      <ContentHeader
        title={
          (postKey !== 'new' ? 'Chỉnh sửa ' : '') +
          (target == 'pages' ? 'Trang' : 'Bài viết')
        }
      />
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <Card style={styles.card}>
              <Form.Item
                name="title"
                label="Tiêu đề"
                rules={[
                  {
                    required: true,
                    message: 'Nhập tiêu đề cho bài viết',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Tiêu đề bài viết ...."
                  style={{ fontWeight: 'bold', fontSize: 16 }}
                />
              </Form.Item>
              <div style={{ margin: '15px 0' }}>
                <label>Tải lên hình ảnh</label>
                <div className="row" style={{ marginRight: 0 }}>
                  <div className="col">
                    <input
                      className="form-control form-control-sm"
                      placeholder="url"
                      onChange={(e) => setImageURL(e.target.value)}
                      value={imageURL}
                    />
                  </div>
                  <Space>
                    <Popover content="Copy to clipboard">
                      <label
                        onClick={handleCopyImageURL}
                        className="btn btn-sm btn-primary"
                      >
                        Copy
                      </label>
                    </Popover>

                    <label
                      htmlFor="inpUploadImage"
                      className="btn btn-sm btn-outline-secondary"
                    >
                      <i className="fas fa-upload"></i> Upload
                    </label>
                  </Space>
                </div>
                <div className="d-none">
                  <input
                    accept={acceptedImage.toString()}
                    type="file"
                    id="inpUploadImage"
                    onChange={(e) => handleImage(e.target.files[0], 'imageURL')}
                  />
                </div>
              </div>
              <Editor
                apiKey="n7gkz7rcwcvy8c5mg4alpw6ntjcgpkt28p9eshjsodw5we8x"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={content !== '' ? content : ''}
                init={{
                  height: 400,
                  menubar: true,
                  plugins: [
                    'advlist',
                    'autolink',
                    'lists',
                    'link',
                    'image',
                    'charmap',
                    'preview',
                    'anchor',
                    'searchreplace',
                    'visualblocks',
                    'code',
                    'fullscreen',
                    'insertdatetime',
                    'media',
                    'table',
                    'code',
                    'help',
                    'wordcount',
                  ],
                  toolbar:
                    'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
              />
              <Form.Item
                style={{ marginTop: 15 }}
                label="Mô tả ngắn"
                name="description"
              >
                <Input.TextArea rows={5} placeholder="mô tả" />
              </Form.Item>
              <Form.Item label="Từ khóa" name="tags">
                <Input placeholder="Nhập từ khóa, phân cách nhau bằng dấu phẩy" />
              </Form.Item>
              {isReview ? (
                <div className="review-contain">
                  <Divider>Phần đánh giá phòng khám</Divider>
                  <Form.Item name="star">
                    <div className="text-center" style={{ padding: 20 }}>
                      <Rate
                        style={{ fontSize: 32 }}
                        onChange={(value) => setStar(value)}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item name="location" label="Địa chỉ phòng khám">
                    <Input />
                  </Form.Item>
                  <div className="row">
                    <div className="col-md">
                      <Form.Item name="openTime" label="Giờ mở cửa">
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-md">
                      <Form.Item name="phone" label="Điện thoại">
                        <Input type="tel" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
          <div className="col-sm-12 col-md-4">
            <Card title={id ? 'Cập nhật' : 'Xuất bản'} style={styles.card}>
              <Col>
                <Form.Item
                  name="isFavoris"
                  initialValue={false}
                  valuePropName="checked"
                >
                  <Checkbox>Ghim lên đầu trang</Checkbox>
                </Form.Item>
                <p>Trạng thái: Bản nháp</p>
                <p>Người đăng: {author && author.displayName}</p>
                <p>
                  {'Ngày: ' +
                    date.getDate() +
                    ' tháng ' +
                    (date.getMonth() + 1) +
                    ' năm ' +
                    date.getFullYear()}
                </p>
              </Col>
              <Row style={{ marginTop: 20 }}>
                <Button
                  onClick={handleSaveDraft}
                  type="primary"
                  ghost
                  style={{ marginRight: 10, marginTop: 10 }}
                >
                  Lưu nháp
                </Button>
                <Button
                  loading={loaddingPublish}
                  type="primary"
                  onClick={handleSubmit}
                  style={{ marginTop: 10 }}
                >
                  {content !== '' ? 'Cập nhật' : 'Xuất bản'}
                </Button>
              </Row>
            </Card>
            <Card title="Chuyên mục" style={styles.card}>
              <Checkbox.Group onChange={setCategory}>
                {categories &&
                  categories.map((item) => (
                    <div key={item.key}>
                      <Checkbox
                        style={{ margin: '5px 0' }}
                        key={item.key}
                        value={item.key}
                      >
                        {item.title}
                      </Checkbox>
                      <br />
                    </div>
                  ))}
              </Checkbox.Group>
            </Card>
            <Card title="Hình đại diện" style={styles.card}>
              <label htmlFor="inpThumbnail">
                <img
                  className="img img-thumbnail"
                  src={thumbnailURL ? thumbnailURL : defaultImageLink}
                />
              </label>
              <input
                accept={acceptedImage.toString()}
                type="file"
                id="inpThumbnail"
                style={{ display: 'none' }}
                onChange={(e) => handleImage(e.target.files[0], 'thumbnail')}
              />
              {loadingUpload > 0 && loadingUpload < 100 ? (
                <Progress percent={loadingUpload} />
              ) : null}

              <p className="text-muted">
                Click để đổi ảnh <i>(*Kích thước khuyến nghị: 768 x 500)</i>
              </p>
            </Card>
          </div>
        </div>
      </Form>
    </div>
  )
}
