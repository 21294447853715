import { Button, Card, Input, message, Space } from 'antd'
import Table, { ColumnType } from 'antd/lib/table'
import { onValue, ref, remove } from 'firebase/database'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ContentHeader } from '../components/ContentHeader'
import { db } from '../firebase/firebaseConfig'
import { Post } from '../models'
import { fronEndURL } from '../notes'

const { Search } = Input

export const Pages = () => {
  const [pages, setPages] = useState<Post[]>([])

  useEffect(() => {
    getPages()
  }, [])

  const getPages = () => {
    onValue(ref(db, 'pages/'), (snap) => {
      if (snap.val()) {
        const items: Post[] = []

        snap.forEach((item) => {
          items.push({
            ...item.val(),
            key: item.key,
          })
        })

        setPages(items)
      }
    })
  }

  const handleDeletePage = (key: string) => {
    if (window.confirm('Are you sure remove this page?')) {
      remove(ref(db, 'pages/' + key)).then(() => {
        message.success('Remove page successfuly')
      })
    }
  }

  const columns = [
    {
      title: 'Tiêu đề',
      key: 'title',
      dataIndex: '',
      render: (item: Post) => (
        <a href={`${fronEndURL}/trang/${item.slug}`}>{item.title}</a>
      ),
    },
    {
      title: 'Mô tả',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: '',
      key: 'btnContain',
      dataIndex: 'key',
      render: (key: string) => (
        <div className="text-right">
          <Space>
            <Link to={'/trang-moi/pages/' + key}>
              <i className="fas fa-edit"></i>
            </Link>
            <Button
              type="text"
              icon={<i className="fas fa-trash-alt"></i>}
              onClick={() => handleDeletePage(key)}
            />
          </Space>
        </div>
      ),
    },
  ]

  return (
    <>
      <ContentHeader title="Trang" />
      <div className="container">
        <Card
          title={
            <div className="row">
              <div className="col text-right">
                <Link
                  to="/trang-moi/pages/new"
                  className="btn btn-sm btn-success"
                >
                  <i className="fas fa-plus"></i> Thêm trang
                </Link>
              </div>
            </div>
          }
        >
          <Table dataSource={pages} columns={columns} />
        </Card>
      </div>
    </>
  )
}
