import { useEffect, useState } from 'react'
import { Form, Input, message, Modal, Select, Button } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { getDatabase, ref, onValue, push, update } from 'firebase/database'
import { Category } from '../models'
import { replacename } from '../utils/replace_name'

const db = getDatabase()
const { Option } = Select

//get data user to get role
const localData = localStorage.getItem('userData')

interface Props {
  onCancel: () => void
  categories: Category[]
  isVisible: boolean
  category?: Category
}

export const AddCategory = (props: Props) => {
  const { onCancel, isVisible, categories, category } = props
  const [loadding, setLoadding] = useState(false)
  const [access, setAccess] = useState('')
  const [form] = Form.useForm()

  useEffect(() => {
    const data = localData ? JSON.parse(localData) : ''

    if (data.access.includes('categories')) {
      setAccess('categories')
    }
  })

  const closeModal = () => {
    form.resetFields()
    onCancel()
  }

  useEffect(() => {
    if (category) {
      form.setFieldsValue({
        title: category.title,
        description: category.description,
        currentCategory: category.currentCategory,
      })
    } else {
      form.resetFields()
    }
  }, [category, isVisible])

  const handleAddCategory = (values: any) => {
    if (access !== 'categories') {
      Modal.warning({
        title: 'Warning',
        content: 'Bạn không được quyền thực hiện hành động này',
      })
    } else {
      //setLoadding(true)
      const data = {
        title: values.title,
        slug: replacename(values.title),
        description: values.description,
        currentCategory: values.currentCategory ? values.currentCategory : '',
        access: access,
      }
      if (category) {
        //update
        update(ref(db, 'categories/' + category.key), data).then(() => {
          message.success('Cập nhật thông tin chuyên mục thành công')
          closeModal()
          setLoadding(false)
        })
      } else {
        try {
          //push to database
          push(ref(db, 'categories/'), data).then(() => {
            console.log('Done')
            setLoadding(false)
            closeModal()
          })
        } catch (error) {
          alert(error)
        }
      }
    }
  }
  return (
    <Modal
      title="Thêm chuyên mục"
      visible={isVisible}
      footer={null}
      onCancel={() => closeModal()}
    >
      <Form layout="vertical" form={form} onFinish={handleAddCategory}>
        <Form.Item
          name="title"
          label="Tên chuyên mục"
          rules={[
            {
              required: true,
              message: 'Nhập tên chuyên mục',
            },
          ]}
        >
          <Input placeholder="eg: Tư vấn sức khỏe" maxLength={100} showCount />
        </Form.Item>
        <Form.Item name="description" label="Mô tả">
          <Input.TextArea
            rows={3}
            placeholder="Nhập mô tả"
            maxLength={500}
            showCount
          />
        </Form.Item>
        <Form.Item name="currentCategory" label="Chuyên mục cha">
          <Select>
            {categories &&
              categories.map((item) => (
                <Option key={item.key} value={item.key}>
                  {item.title}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            loading={loadding}
            type="primary"
            onClick={() => form.submit()}
          >
            Đồng ý
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
