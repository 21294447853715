import { Form, message, Modal, Select } from 'antd'
import { onValue, push, ref, update } from 'firebase/database'
import { useForm } from 'rc-field-form'
import { useEffect, useState } from 'react'
import { db } from '../firebase/firebaseConfig'
import { Member, Schedule } from '../models'

const { Option } = Select

interface Props {
  onCancel: () => void
  schedule?: Schedule
  visible: boolean
}

export const SwitchDoctorModal = (props: Props) => {
  const [doctors, setDoctors] = useState<Member[]>([])
  const [form] = Form.useForm()
  const { onCancel, schedule, visible } = props

  useEffect(() => {
    //get doctor and create option
    getMembers()

    schedule &&
      form.setFieldsValue({
        oldUsername: schedule.idDoctor,
      })
  }, [visible])

  //get doctor active list
  const getMembers = () => {
    const items: Member[] = []
    onValue(ref(db, 'members/'), (snap) => {
      if (snap.val()) {
        snap.forEach((item) => {
          //only doctor active
          if (item.val().isActive) {
            items.push({
              ...item.val(),
              key: item.key,
            })
          }
        })
      }

      setDoctors(items)
    })
  }

  const handleSwitchDoctor = (values: any) => {
    if (values.newUsername == '') {
      Modal.warning({
        title: 'Chọn bác sĩ',
        content: 'Bạn cần chọn 1 bác sĩ khác thay thế',
      })
    } else {
      // console.log(values.newUsername)

      update(ref(db, 'schedules/' + schedule?.key), {
        idDoctor: values.newUsername,
      }).then(() => {
        //send to old doctor
        push(ref(db, 'members/' + values.oldUsername + '/alerts'), {
          title: 'Đổi người tư vấn',
          content:
            'Buổi hẹn tư vấn của bạn đã được chuyển giao đến bác sĩ khác',
          id: schedule?.key,
          type: 'schedules',
          read: false,
        })

        //send to new doctor

        push(ref(db, 'members/' + values.newUsername + '/alerts'), {
          title: 'Đổi người tư vấn',
          content: 'Bạn nhận được buổi hẹn tư vấn từ bác sĩ khác',
          id: schedule?.key,
          type: 'schedules',
          read: false,
        })

        message.success('Lịch hẹn tư vấn đã được cập nhật thành công')
        handleCloseModal()
      })
    }
  }

  const handleCloseModal = () => {
    form.resetFields()
    onCancel()
  }

  return (
    <Modal
      onCancel={handleCloseModal}
      title="Thay đổi Bác sĩ tư vấn"
      footer={null}
      visible={visible}
    >
      <Form form={form} layout="vertical" onFinish={handleSwitchDoctor}>
        <Form.Item name="oldUsername" label="Bác sĩ hiện tại">
          <Select size="large" disabled>
            {doctors.map((doctor) => (
              <Option key={doctor.key} value={doctor.key}>
                {doctor.displayName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="newUsername"
          label="Chọn một Bác sĩ thay thế"
          initialValue=""
        >
          <Select size="large">
            <Option value="">Vui lòng chọn</Option>
            {doctors.map((doctor) =>
              doctor.key !== schedule?.idDoctor ? (
                <Option key={doctor.key} value={doctor.key}>
                  {doctor.displayName}
                </Option>
              ) : null,
            )}
          </Select>
        </Form.Item>
      </Form>
      <div className="text-right">
        <button
          onClick={() => form.submit()}
          className="btn btn-sm btn-success"
        >
          Đồng ý
        </button>
      </div>
    </Modal>
  )
}
