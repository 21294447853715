import { Button, Form, Input, message, Modal } from 'antd'
import { onValue, ref, update } from 'firebase/database'
import { sha256 } from 'js-sha256'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { db } from '../firebase/firebaseConfig'
import { Member } from '../models'

interface Props {
  isVisible: boolean
  closeModal: () => void
  member?: Member
}

export const ChangePass = (props: Props) => {
  const { isVisible, closeModal } = props
  const [form] = Form.useForm()
  const [isNotMatch, setIsNotMatch] = useState(false)
  const [userData, setUserData] = useState<any>()

  useEffect(() => {
    const jsonData = localStorage.getItem('userData')
    jsonData && setUserData(JSON.parse(jsonData))
  }, [isVisible])

  const handleChangePass = (values: any) => {
    try {
      if (values.newPass === values.reNewPass) {
        onValue(ref(db, 'members/' + userData.username), (snap) => {
          if (snap.val()) {
            if (sha256(values.currentPass) === snap.val().password) {
              update(ref(db, 'members/' + userData.username), {
                password: sha256(values.newPass),
              }).then(() => {
                closeModal()
                Modal.success({
                  title: 'Chúc mừng',
                  content:
                    'Đổi mật khẩu thành công, bạn hãy đăng nhập lại bằng mật khẩu mới của mình',
                  onOk: () => {
                    localStorage.clear()
                    window.location.reload()
                  },
                })
              })
            } else {
              Modal.error({
                title: 'Lỗi',
                content:
                  'Mật khẩu hoặc tài khoản không chính xác, xin vui lòng thử lại',
              })
            }
          } else {
            localStorage.clear()
          }
        })
      } else {
        form.resetFields()
        setIsNotMatch(true)
      }
    } catch (error) {
      Modal.error({
        title: 'Error',
        content:
          'Đổi mật khẩu thất bại, xin vui lòng kiểm tra lại và thử lại sau',
      })
    }
  }

  return (
    <Modal
      footer={null}
      visible={isVisible}
      onCancel={closeModal}
      title="Đổi mật khẩu"
    >
      <Form onFinish={handleChangePass} form={form} layout="vertical">
        <Form.Item
          name="currentPass"
          label="Mật khẩu cũ"
          rules={[
            {
              required: true,
              message: 'Không được bỏ trống',
            },
          ]}
        >
          <Input.Password placeholder="Nhập lại mật khẩu cũ" minLength={6} />
        </Form.Item>

        <Form.Item
          name="newPass"
          label="Nhập mật khẩu mới"
          hasFeedback
          validateStatus={isNotMatch ? 'error' : ''}
          help={isNotMatch ? 'Mật khẩu không trùng khớp' : ''}
        >
          <Input.Password placeholder="Nhập mật khẩu,." minLength={6} />
        </Form.Item>

        <Form.Item
          name="reNewPass"
          label="Nhập lại mật khẩu mới"
          hasFeedback
          validateStatus={isNotMatch ? 'error' : ''}
          help={isNotMatch ? 'Mật khẩu không trùng khớp' : ''}
        >
          <Input.Password placeholder="Nhập lại mật khẩu,." minLength={6} />
        </Form.Item>

        <Form.Item>
          <div className="col text-right" style={{ marginTop: 20 }}>
            <Button onClick={() => form.submit()} type="primary">
              Đồng ý
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}
