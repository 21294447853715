import { PageHeader } from 'antd'
import { JsxElement } from 'typescript'
import { styles } from '../styles'

interface Props {
  title: string
  button?: any | JsxElement
}

export const ContentHeader = (props: Props) => {
  return (
    <PageHeader
      style={styles.contentHeader}
      className="site-page-header"
      title={<h1 style={{ color: '#1b3a57', fontSize: 32 }}>{props.title}</h1>}
      extra={[props.button]}
    />
  )
}
