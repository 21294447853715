import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Select,
  Space,
  Table,
  Tag,
} from 'antd'
import { ColumnsType } from 'antd/lib/table/interface'
import { getDatabase, onValue, ref, remove, update } from 'firebase/database'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Category, Member, Post } from '../models'
import { fronEndURL } from '../notes'
import { getDoctorName } from '../utils'

interface Props {
  data: Post[]
}
const db = getDatabase()
const { Option } = Select

export const TablePost = (props: Props) => {
  const { data } = props
  const [action, setAction] = useState('')
  const [selectedRowkeys, setSelectedRowkeys] = useState<React.Key[]>([])
  const [members, setMembers] = useState<Member[]>([])
  const [categories, setCategories] = useState<Category[]>([])

  useEffect(() => {
    onValue(ref(db, 'members/'), (snapMember) => {
      let items: Member[] = []
      if (snapMember.val()) {
        snapMember.forEach((item) => {
          items.push({
            ...item.val(),
            uid: item.key,
          })
          setMembers(items)
        })
      }
    })
  }, [])

  useEffect(() => {
    onValue(ref(db, 'categories/'), (snapCats) => {
      let items: Category[] = []
      if (snapCats.val()) {
        snapCats.forEach((item) => {
          items.push({
            ...item.val(),
            key: item.key,
          })
          setCategories(items)
        })
      }
    })
  }, [])

  const handleRemoveFavoris = (id: string) => {
    update(ref(db, 'posts/' + id), {
      isFavoris: false,
    }).then(() => console.log('Done'))
  }
  const columns: ColumnsType<Post> = [
    {
      title: 'Tiêu đề',
      dataIndex: '',
      key: 'title',
      width: '30%',
      render: (item) => (
        <div key={item.key}>
          <Popover content="Xem">
            <a target="blank" href={fronEndURL + 'bai-viet/' + item.slug}>
              {item.title}
            </a>
          </Popover>
          {item.isFavoris ? (
            <Popover content="Bỏ ghim">
              <Button
                onClick={() => handleRemoveFavoris(item.key)}
                type="text"
                icon={<i className="fas fa-bell-slash text-muted"></i>}
              ></Button>
            </Popover>
          ) : null}
        </div>
      ),
    },
    {
      title: 'Tác giả',
      dataIndex: 'author',
      key: 'author',
      render: (author: string) => (
        <p style={{ margin: 0, textTransform: 'capitalize' }}>
          {getDoctorName(author)}
        </p>
      ),
    },
    {
      title: 'Chuyên mục',
      dataIndex: 'categories',
      key: 'categories',
      render: (cats) =>
        cats &&
        cats.map((cat: string) =>
          categories.map(
            (item: Category) =>
              item.key == cat && item.title + (cats.length > 1 ? ', ' : ''),
          ),
        ),
      width: '15%',
    },
    {
      title: <i className="fas fa-eye text-muted"></i>,
      dataIndex: 'view',
      key: 'view',
    },
    {
      title: <i className="fas fa-thumbs-up text-muted"></i>,
      dataIndex: 'like',
      key: 'like',
    },
    {
      title: <i className="fas fa-comment text-muted"></i>,
      dataIndex: 'comments',
      key: 'comment',
      render: (comments: string[]) => (comments ? comments.length : 0),
    },

    { title: 'Trạng thái', dataIndex: 'state', key: 'state' },
    {
      title: '',
      dataIndex: 'key',
      key: 'btnContain',
      render: (key: string) => (
        <Link key={key} to={'/bai-moi/posts/' + key} className="text-muted">
          <i className="fas fa-edit"></i>
        </Link>
      ),
    },
  ]

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowkeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowkeys,
    onChange: onSelectChange,
  }

  const handleChangeAction = (value: string) => {
    setAction(value)
  }

  const onAction = () => {
    if (!action) {
      console.log('fsjah')
    } else {
      if (action == 'remove') {
        //Chuyển vào thùng rác
        console.log('Chuyển bài viết vào thùng rác')
        //Thực chất là cập nhật thành trạng thái remove
        selectedRowkeys.forEach((key) => {
          update(ref(db, 'posts/' + key), {
            state: 'Đã xóa',
          }).then(() => {
            message.info('Đã chuyển vào thùng rác')
          })
        })
      }

      if (action == 'delete') {
        //Xóa vĩnh viễn khỏi database
        if (selectedRowkeys.length > 0) {
          Modal.confirm({
            title: 'Xác nhận',
            content: 'Xóa các bài viết đã chọn',
            onOk: () => {
              selectedRowkeys.forEach((id) => {
                remove(ref(db, 'posts/' + id)).then(() => {
                  message.success('Đã xóa')
                })
              })
            },
          })
        } else {
          message.info('Chọn bài viết trước')
        }
      }

      if (action == 'pinTop') {
        //Ghim bài viết lên đầu
        if (selectedRowkeys.length > 0) {
          selectedRowkeys.forEach((id) => {
            update(ref(db, 'posts/' + id), {
              isFavoris: true,
            }).then(() => {
              message.success('Cập nhật thành công')
            })
          })
        } else {
          message.info('Chọn bài viết trước')
        }
      }
      setSelectedRowkeys([])
    }
  }

  return (
    <>
      <Space style={{ marginBottom: 10 }}>
        <Select
          defaultValue="Chọn hành động"
          style={{ minWidth: 250 }}
          onChange={handleChangeAction}
        >
          <Option value="remove" key="remove">
            Chuyển vào thùng rác
          </Option>
          <Option value="delete" key="del">
            Xóa vĩnh viễn
          </Option>
          <Option value="pinTop">Ghim lên đầu</Option>
        </Select>
        <Button onClick={onAction} type="primary" ghost>
          Đồng ý
        </Button>
      </Space>

      <Table rowSelection={rowSelection} dataSource={data} columns={columns} />
    </>
  )
}
