export const acceptedImage = [
  'image/jpg',
  'image/png',
  'image/webp',
  'image/tiff',

  'image/raw',
  'image/bmp',
  'image/heif',

  'image/jpeg',
  'image/svg',
]
