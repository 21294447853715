import { Card, Tabs } from 'antd'
import { useParams } from 'react-router-dom'
import { ContentHeader } from '../components/ContentHeader'
import { MemberInfo } from '../components/members/member_info'
import { ScheduleInfo } from '../components/members/schedules'

const { TabPane } = Tabs

export const MemberManagement = () => {
  const { name } = useParams()
  return (
    <>
      <ContentHeader title="Thành viên" />
      <Card style={{ marginTop: 20 }}>
        <Tabs>
          <TabPane key={1} tab="Thông tin">
            <MemberInfo />
          </TabPane>
          {name !== 'add-new' ? (
            <TabPane key={2} tab="Lịch hẹn tư vấn">
              <ScheduleInfo />
            </TabPane>
          ) : null}
        </Tabs>
      </Card>
    </>
  )
}
