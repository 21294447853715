import { Button, Card, Input, message, Modal, Popover, Table, Tag } from 'antd'
import {
  equalTo,
  getDatabase,
  onValue,
  orderByChild,
  query,
  ref,
  remove,
  update,
} from 'firebase/database'
import { useEffect, useState } from 'react'
import { ContentHeader } from '../components/ContentHeader'
import { Member } from '../models'
import { Link } from 'react-router-dom'
import { RolesMembers } from '../modals/Index'
import { replaceSearchKey } from '../utils/replaceSearchKey'
import { ColumnsType } from 'antd/lib/table/interface'

const db = getDatabase()
const { Search } = Input

export const Members = () => {
  const [members, setMembers] = useState<Member[]>([])
  const [visible, setVisible] = useState(false)
  const [memberId, setMemberId] = useState('')
  const [accessValues, setAccessValues] = useState<string[]>([''])
  const closeModal = () => {
    setVisible(false)
    setMemberId('')
  }

  useEffect(() => {
    getMember()
  }, [])

  //
  const getMember = (q?: string) => {
    onValue(ref(db, 'members/'), (snap) => {
      const data: Member[] = []
      if (snap.val()) {
        snap.forEach((item) => {
          if (q && q !== '') {
            if (item.val().email.includes(replaceSearchKey(q))) {
              data.push({
                ...item.val(),
                key: item.key,
              })
            }
          } else {
            data.push({
              ...item.val(),
              key: item.key,
            })
          }
        })
      }
      setMembers(data)
    })
  }

  const handleDeleteMember = (key: string) => {
    Modal.confirm({
      title: 'Lưu ý',
      content:
        'Bạn muốn xóa thành viên này khỏi hệ thống, điều này cũng sẽ xóa toàn bộ thông tin, bài viết, lịch hẹn... liên quan đến thành viên này',
      okText: 'Đồng ý',
      cancelText: 'Hủy',
      onOk: () => {
        //xóa toàn bộ thông tin liên quan
        //xóa bài viết
        removeItemsWithMember('posts/', key)
        //xóa lịch hẹn
        removeItemsWithMember('schedules/', key)

        //xóa thành viên
        remove(ref(db, '/members/' + key)).then(() => {
          message.success('Đã xóa')
        })
      },
    })
  }

  const removeItemsWithMember = (target: string, memberId: string) => {
    remove(ref(db, target + memberId))
  }

  const showModalRoles = async (item: Member) => {
    if (item) {
      setAccessValues(item.access)
      setMemberId(item.userName)
    } else {
      setAccessValues([''])
    }
    setVisible(true)
  }

  const handleActiveMember = (id: string) => {
    Modal.confirm({
      title: 'Xác nhận',
      content: 'Kích hoạt tính năng nhận lịch hẹn',
      onOk: () => {
        update(ref(db, 'members/' + id), {
          isActive: true,
        }).then(() => {
          message.success('Kích hoạt tính năng nhận lịch hẹn thành công')
        })
      },
    })
  }

  const getCountPost = (id: string) => {
    let countPost = 0
    const filterAuthor = query(
      ref(db, 'posts/'),
      orderByChild('author'),
      equalTo(id),
    )
    onValue(filterAuthor, (snap) => {
      if (snap.val()) {
        snap.forEach((item) => {
          countPost += 1
        })
      }
    })
    return countPost.toString()
  }

  const getCountSchedules = (id: string) => {
    let countSchedule = 0
    const filterAuthor = query(
      ref(db, 'schedules/'),
      orderByChild('idDoctor'),
      equalTo(id),
    )
    onValue(filterAuthor, (snap) => {
      if (snap.val()) {
        snap.forEach((item) => {
          countSchedule += 1
        })
      }
    })
    return countSchedule.toString()
  }

  const columns: ColumnsType<Member> = [
    {
      title: 'Họ và Tên',
      dataIndex: '',
      key: 'displayName',
      render: (item: Member) => (
        <Link to={'/thanh-vien/' + 'info' + '/' + item.userName}>
          <h1
            style={{
              color: '#676767',
              fontSize: '1rem',
              textTransform: 'capitalize',
              margin: 0,
              padding: 0,
            }}
          >
            {item.displayName}
          </h1>
          {item.isActive ? (
            <Tag color="#87d068">Đã kích hoạt</Tag>
          ) : (
            <Tag>Chưa kích hoạt</Tag>
          )}
        </Link>
      ),
    },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'userName',
      key: 'phone',
    },
    {
      title: 'Chức danh',
      dataIndex: 'title',
      key: 'title',
      render: (title: string) => (
        <p style={{ textTransform: 'capitalize', margin: 0 }}>{title}</p>
      ),
    },
    {
      title: 'Quyền',
      dataIndex: 'role',
      key: 'role',
      filters: [
        { text: 'Quản trị viên', value: 'Quản trị viên' },
        { text: 'Kiểm duyệt nội dung', value: 'Kiểm duyệt nội dung' },
        { text: 'Thư ký', value: 'Thư ký' },
        { text: 'Cộng tác viên', value: 'Cộng tác viên' },
        { text: 'Thành viên', value: 'Thành viên' },
      ],
      onFilter: (value: any, record) => record.role.startsWith(value),
    },

    {
      title: <i className="fas fa-file-signature"></i>,
      dataIndex: 'userName',
      key: '',
      render: (userName: string) => getCountPost(userName),
    },
    {
      title: <i className="fas fa-file-prescription"></i>,
      dataIndex: 'userName',
      key: '',
      render: (userName: string) => getCountSchedules(userName),
    },
    {
      title: '',
      dataIndex: '',
      key: 'btnContain',
      render: (item: Member) => (
        <div className="text-right">
          {!item.isActive ? (
            <Popover content="Kích hoạt tính năng nhận lịch hẹn">
              <Button
                type="text"
                onClick={() => handleActiveMember(item.userName)}
                icon={<i className="fas fa-check text-success"></i>}
              />
            </Popover>
          ) : null}
          <Popover content="Chỉnh sửa">
            <Link to={'/thanh-vien/' + 'edit' + '/' + item.userName}>
              <Button
                type="text"
                icon={<i className="fas fa-edit text-muted"></i>}
              />
            </Link>
          </Popover>
          <Popover content="Phân quyền">
            <Button
              type="text"
              onClick={() => showModalRoles(item)}
              icon={<i className="fas fa-user-tag text-success"></i>}
            />
          </Popover>
          <Popover content="Xóa thành viên">
            <Button
              onClick={() => handleDeleteMember(item.userName)}
              type="text"
              icon={<i className="fas fa-user-slash text-danger"></i>}
            />
          </Popover>
        </div>
      ),
    },
  ]

  return (
    <div>
      <ContentHeader title="Danh sách thành viên" />

      {/* Hiển thị danh sách thành viên */}
      <Card
        title={
          <Search
            size="large"
            allowClear
            style={{ maxWidth: '50%' }}
            placeholder="user name hoặc email"
            onSearch={(val) => getMember(val)}
          />
        }
        extra={
          <Link
            to="/thanh-vien/add-new/them-moi"
            className="btn btn-sm btn-success"
          >
            <i className="fas fa-user-plus"></i> Thêm thành viên
          </Link>
        }
        style={{ marginTop: 20 }}
      >
        <Table dataSource={members} columns={columns} />
      </Card>

      <RolesMembers
        closeModal={closeModal}
        visible={visible}
        idMember={memberId}
        accessValues={accessValues}
      />
    </div>
  )
}
