import { Button, Card, Form, Input, message, Modal, Popover } from 'antd'
import { ref, update } from 'firebase/database'
import { useEffect, useState } from 'react'
import { db } from '../firebase/firebaseConfig'
import { Setting } from '../models'

interface Props {
  setting: Setting | undefined
  getSetting: () => void
}

export const SettingYoutubeVideos = (props: Props) => {
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const { setting, getSetting } = props

  const handleUpdateSocialList = (values: any) => {
    update(ref(db, 'settings/youtubeVideos/'), values).then(() => {
      message.success('Cập nhật videos nổi bật thành công')
    })
  }

  const videos = setting?.videos

  const handleUpdateVideoLink = (values: any) => {
    const url = values.linkVideo.split('https://youtu.be/')[1]

    let items: string[] = []
    if (setting?.videos) {
      items = setting.videos
    }

    items.push(url)

    update(ref(db, 'settings/'), {
      videos: items,
    }).then(() => {
      console.log('Done')
      form.resetFields()
      setVisible(false)
    })
  }

  const handleRemoveVideo = (index: number) => {
    videos?.splice(index, 1)

    update(ref(db, 'settings/'), {
      videos: videos,
    }).then(() => {
      console.log('Done')
    })
  }

  return (
    <div className="col-md">
      <Card
        title={
          <div className="text-right">
            <button
              onClick={() => setVisible(true)}
              className="btn btn-sm btn-success"
            >
              <i className="fas fa-plus"></i> Thêm video
            </button>
          </div>
        }
      >
        <p>
          <i className="text-muted">
            Trang web chỉ có thể hiển thị tối đa 6 video, nếu bạn thêm nhiều hơn
            6 videos, những video mới nhất sẽ được ưu tiên
          </i>
        </p>
        <div className="row">
          {videos?.map((video) => (
            <div
              className=""
              style={{ position: 'relative' }}
              key={videos.indexOf(video)}
            >
              <iframe
                style={{ margin: 10 }}
                width="320"
                height="220"
                src={'https://youtube.com/embed/' + video}
                title="YouTube video player"
                frameBorder={1}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={false}
              ></iframe>
              <Popover content="Delete video">
                <Button
                  style={{ position: 'absolute', top: 10, right: 10 }}
                  onClick={() => handleRemoveVideo(videos.indexOf(video))}
                  type="primary"
                  danger
                  icon={<i className="fas fa-trash-alt"></i>}
                ></Button>
              </Popover>
            </div>
          ))}
        </div>
      </Card>

      <Modal
        footer={null}
        visible={visible}
        onCancel={() => {
          form.resetFields()
          setVisible(false)
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleUpdateVideoLink}>
          <Form.Item name="linkVideo" label="Link video">
            <Input placeholder="https://youtu.be/GRLMnGcTRHs" />
          </Form.Item>

          <p>Đây là link chia sẻ video chứ không phải link nhúng nhé</p>
          <p className="text-muted">
            Bấm vào nút chia sẻ, bấm vào nút copy ở màn hình chia sẻ hiện lên và
            paste vào đây
          </p>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Đồng ý
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
