import { Avatar, Button, Card, List, message, Modal, Popover } from 'antd'
import Item from 'antd/lib/list/Item'
import { onValue, ref, remove, update } from 'firebase/database'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ContentHeader } from '../components/ContentHeader'
import { db } from '../firebase/firebaseConfig'
import { CommentItem, User } from '../models'
import { avatarDefault, defaultImageLink } from '../utils/default_image_link'

export const Comments = () => {
  const [comments, setComments] = useState<CommentItem[]>([])
  const [patients, setPatients] = useState<User[]>([])

  useEffect(() => {
    getComments()
    getPatients()
  }, [])

  const getComments = () => {
    const items: CommentItem[] = []
    onValue(ref(db, 'posts/'), (snap) => {
      if (snap.val()) {
        snap.forEach((item) => {
          if (item.val().comments) {
            const commentsPost = item.val().comments
            for (let key in commentsPost) {
              items.unshift({
                ...commentsPost[key],
                key: key,
                postId: item.key,
              })
            }
          }
        })

        setComments(items)
      }
    })
  }

  const getPatients = () => {
    const items: User[] = []

    onValue(ref(db, 'users/'), (snap) => {
      snap.val() &&
        snap.forEach((item) => {
          items.push({
            ...item.val(),
            uid: item.key,
          })
        })
      setPatients(items)
    })
  }

  const getPatientName = (id: string, target: string) => {
    let name = ''
    let avatar = ''
    patients.forEach((item) => {
      console.log(item)

      if (item.uid === id) {
        avatar = item.photoURL as string
        name = item.displayName as string
      }
    })
    return target === 'name' ? name : avatar
  }

  const getPostTitle = (key?: string) => {
    let title = ''
    onValue(ref(db, 'posts/' + key), (snap) => {
      if (snap.val()) {
        title = snap.val().title
      }
    })

    return title
  }

  const removeComment = (postId?: string, key?: string) => {
    Modal.confirm({
      title: 'Xóa bình luận',
      content: 'Bạn chắc chắn muốn xóa bình luận này?',
      onOk: () => {
        remove(ref(db, 'posts/' + postId + '/comments/' + key)).then(() => {
          getComments()
          message.success('Đã xóa bình luận')
        })
      },
    })
  }

  const onVerifiedComment = (
    postId?: string,
    key?: string,
    isVerified?: boolean,
  ) => {
    update(ref(db, 'posts/' + postId + '/comments/' + key), {
      isVerified: !isVerified,
    }).then(() => {
      getComments()
      message.success(isVerified ? 'Đã khóa' : 'Đã mở khóa')
    })
  }

  return (
    <div className="container-fluid">
      <ContentHeader title="Bình luận" />
      <Card>
        <List
          dataSource={comments}
          renderItem={(item) => (
            <List.Item
              key={item.key}
              actions={[
                <Popover
                  key="btn-unlock"
                  content={!item.isVerified ? 'Mở khóa' : 'Khóa'}
                >
                  <Button
                    onClick={() =>
                      onVerifiedComment(
                        item?.postId,
                        item?.key,
                        item.isVerified,
                      )
                    }
                    type="text"
                    icon={
                      <i
                        className={
                          item.isVerified
                            ? 'fas fa-lock-open text-success'
                            : 'fas fa-lock text-muted'
                        }
                      ></i>
                    }
                  />
                </Popover>,
                <Popover content="Xóa bình luận" key="btn-remove">
                  <Button
                    type="text"
                    onClick={() => removeComment(item?.postId, item?.key)}
                    icon={<i className="fas fa-trash-alt text-muted"></i>}
                  />
                  ,
                </Popover>,
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar src={avatarDefault} />}
                description={'Bài viết: ' + getPostTitle(item.postId)}
                title={
                  <>
                    <Link to={'/thong-tin-nguoi-dung/' + item.uid}>
                      {getPatientName(item.uid, 'name')}
                    </Link>
                  </>
                }
              />
              {'Nội dung: ' + item.content}
            </List.Item>
          )}
        ></List>
      </Card>
    </div>
  )
}
