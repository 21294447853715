import { isVisible } from '@testing-library/user-event/dist/utils'
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Form,
  Input,
  List,
  message,
  Modal,
  Select,
  Table,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { onValue, push, ref, remove } from 'firebase/database'
import { useEffect, useState } from 'react'
import { ContentHeader } from '../components/Index'
import { db } from '../firebase/firebaseConfig'
import { Member, Special } from '../models'

const { Option } = Select

export const SpecialMedical = () => {
  const [specials, setSpecials] = useState<Special[]>([])
  const [isVisible, setIsVisible] = useState(false)
  const [doctors, setDoctors] = useState<Member[]>([])

  const [form] = Form.useForm()

  useEffect(() => {
    getSpecialMedical()
    getMembers()
  }, [])

  const getSpecialMedical = () => {
    const items: Special[] = []
    onValue(ref(db, 'specials/'), (snap) => {
      snap.val() &&
        snap.forEach((item) => {
          items.push({
            ...item.val(),
            key: item.key,
          })
        })

      setSpecials(items)
    })
  }

  //get members for get name
  const getMembers = () => {
    onValue(ref(db, 'members/'), (snap) => {
      const items: Member[] = []
      if (snap.val()) {
        snap.forEach((item) => {
          items.push({
            ...item.val(),
            key: item.key,
          })
        })
      }

      setDoctors(items)
    })
  }

  const removeSpecial = (key?: string) => {
    Modal.confirm({
      title: 'Xóa chuyên khoa',
      content:
        'Việc này sẽ không thể khôi phục được, bạn có muốn tiếp tục không?',
      onOk: () => {
        remove(ref(db, 'specials/' + key)).then(() => {
          message.success('Đã xóa')
        })
      },
    })
  }

  const addSpecial = (values: any) => {
    push(ref(db, 'specials/'), values).then(() => {
      message.success('Đã thêm chuyên khoa')
      form.resetFields()
      setIsVisible(false)
    })
  }

  return (
    <div className="container">
      <ContentHeader title="Danh sách chuyên khoa" />
      <Card
        title={
          <button
            onClick={() => setIsVisible(true)}
            className="btn btn-sm btn-success"
          >
            Thêm chuyên khoa
          </button>
        }
      >
        Danh sách các chuyên khoa nhận tư vấn
        <List>
          {specials.map((item: Special) => (
            <List.Item
              key={item.key}
              actions={[
                <Button
                  onClick={() => removeSpecial(item.key)}
                  type="text"
                  icon={<i className="fas fa-trash-alt text-muted"></i>}
                />,
              ]}
            >
              <List.Item.Meta
                title={item.title}
                description={item.description}
              />
              Phụ trách: {item.idDoctor}
            </List.Item>
          ))}
        </List>
      </Card>
      <Modal
        onCancel={() => {
          setIsVisible(false)
          form.resetFields()
        }}
        footer={null}
        visible={isVisible}
        style={{ minWidth: 400, padding: 20 }}
      >
        <Form form={form} layout="vertical" onFinish={addSpecial}>
          <Form.Item name="title" label="Tên chuyên khoa" initialValue="">
            <Input />
          </Form.Item>

          <Form.Item name="description" label="Mô tả" initialValue="">
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            name="idDoctor"
            label="Người phụ trách"
            initialValue="admin"
          >
            <Select>
              {doctors.map((doc) => (
                <Option key={doc.key} value={doc.key}>
                  {doc.displayName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <div className="text-right">
            <button className="btn btn-sm btn-success">
              <i className="fas fa-check"></i> Đồng ý
            </button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}
