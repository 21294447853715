//this is register page demo
import { useState } from 'react'
import { Button, Card, Divider, Form, Input, message, Row } from 'antd'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { getDatabase, ref, set } from 'firebase/database'
import { useNavigate } from 'react-router-dom'

const auth = getAuth()
const db = getDatabase()

export const Register = () => {
  const navigate = useNavigate()
  const [loadding, setLoadding] = useState(false)
  const [error, setError] = useState('')

  const handleRegister = (values) => {
    setLoadding(true)

    createUserWithEmailAndPassword(auth, values.email, values.pass)
      .then((userCredential) => {
        setLoadding(false)
        const user = userCredential.user
        //Tạo tài khoản thành công

        //Lưu tài khoản vào database
        set(ref(db, 'members/' + user.uid), {
          email: user.email,
          isEmailVerified: user.emailVerified,
          isActive: false,
          regisDate: new Date().getTime(),
        }).then(() => {
          message.info('Đăng ký tài khoản thành công')
          navigate('/')
        })
      })
      .catch((e) => {
        setLoadding(false)
        setError(e.message)
      })
  }

  return (
    <div>
      <Row justify="center">
        <Card style={{ minWidth: 500 }}>
          <Form name="register" layout="vertical" onFinish={handleRegister}>
            <Row justify="center">
              <h1>Đăng ký</h1>
            </Row>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: 'Bạn chưa nhập email',
                },
              ]}
            >
              <Input type="email" placeholder="email" />
            </Form.Item>
            <Form.Item
              name="pass"
              label="Mật khẩu"
              rules={[
                {
                  required: true,
                  message: 'Nhập mật khẩu',
                },
              ]}
            >
              <Input placeholder="Mật khẩu" type="password" />
            </Form.Item>
            <p className="text-muted">{error}</p>
            <Form.Item>
              <div className="text-center">
                <Button
                  loading={loadding}
                  style={{
                    width: '50%',
                    backgroundColor: '#27ae60',
                    border: 'none',
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Đăng ký
                </Button>
              </div>
            </Form.Item>
          </Form>
          <Divider orientation="center" plain>
            Hoặc đăng nhập bằng
          </Divider>
          <div className="text-center">
            <button
              style={{ width: '50%', margin: 1 }}
              className="btn btn-sm btn-primary"
            >
              Facebook
            </button>
            <button
              style={{ width: '50%', margin: 1 }}
              className="btn btn-sm btn-danger"
            >
              Google
            </button>
          </div>
        </Card>
      </Row>
    </div>
  )
}
