import { Button, Card, Form, Input, message, Progress, Tabs } from 'antd'
import { onValue, update } from 'firebase/database'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { useEffect, useState } from 'react'
import { db, storage } from '../firebase/firebaseConfig'
import * as firebaseApp from 'firebase/database'
import { Setting } from '../models'
import { defaultImageLink } from '../utils/default_image_link'

const { TabPane } = Tabs
const refDb = firebaseApp.ref

interface Props {
  setting: Setting | undefined
  getSetting: () => void
}

export const SettingInfo = (props: Props) => {
  const [logo, setLogo] = useState('')
  const [showProgress, setShowProgress] = useState(false)
  const [percent, setPercent] = useState(0)
  const [loadding, setLoadding] = useState(false)

  const { setting, getSetting } = props

  useEffect(() => {
    if (setting) {
      setLogo(setting.logo)

      form.setFieldsValue(setting)
    }
  }, [setting])

  const [form] = Form.useForm()

  const handleUploadLogo = (files: any) => {
    //upload file and set url
    const uploadTask = uploadBytesResumable(
      ref(storage, 'logos/' + files[0].name),
      files[0],
    )

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setPercent(progress)
        setShowProgress(true)
      },
      (error) => {
        console.log(error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setShowProgress(false)
          setLogo(downloadURL)
        })
      },
    )
  }

  const handleUpdateProfile = (values: any) => {
    update(refDb(db, 'settings/'), {
      ...values,
      logo: logo,
    }).then(() => {
      message.success('Cập nhật thành công')
    })
  }
  return (
    <div className="col-md-8 offset-md-2">
      <Card>
        <Form form={form} layout="vertical" onFinish={handleUpdateProfile}>
          <p>
            <i className="text-muted">
              Những nội dung bạn thay đổi ở đây sẽ được thay đổi trên trang web
              chính thức
            </i>
          </p>
          <h5>Logo</h5>
          <label htmlFor="inpLogo">
            <img
              src={logo !== '' ? logo : defaultImageLink}
              alt="Logo"
              style={{ width: 192, height: 80 }}
            />
          </label>

          <p style={{ margin: '10px 0 5px 0 0' }} className="text-muted">
            Kích thước khuyến nghị 192 x 90
          </p>
          <Form.Item>
            <Input
              onChange={(e) => handleUploadLogo(e.target.files)}
              id="inpLogo"
              className="form-control form-control-sm"
              type="file"
              aria-label="Tải lên"
            />
          </Form.Item>
          <Progress
            percent={percent}
            style={{
              marginBottom: 20,
              display: showProgress ? 'block' : 'none',
            }}
          />

          <Form.Item label="Tiêu đề trang web" name="title">
            <Input placeholder="DrView" />
          </Form.Item>

          <Form.Item name="description" label="Mô tả ngắn">
            <Input placeholder="Mô tả ngắn để người dùng biết trang nội dung trang web của bạn" />
          </Form.Item>

          <Form.Item name="hotline" label="Số điện thoại nhận hỗ trợ">
            <Input placeholder="1900..." type="number" />
          </Form.Item>

          <Form.Item name="address" label="Địa chỉ">
            <Input placeholder="Địa chỉ công ty hoặc đại diện công ty" />
          </Form.Item>

          <Form.Item name="email" label="Email">
            <Input placeholder="admin@drview.com" type="email" />
          </Form.Item>

          <Form.Item>
            <div className="col text-right">
              <Button htmlType="submit" loading={loadding} type="primary">
                Cập nhật
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}
