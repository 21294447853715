import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  List,
  Modal,
  Popover,
  Space,
  Tabs,
} from 'antd'
import { onValue, push, ref, remove } from 'firebase/database'
import { useEffect, useState } from 'react'
import { ContentHeader } from '../components/ContentHeader'
import { db } from '../firebase/firebaseConfig'
import { Category, Menu, Post } from '../models'

const { TabPane } = Tabs

export const Menus = () => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState<{
    categories: any
    menus: any
    pages: any
  }>()
  const [menuId, setMenuId] = useState('')
  const [menus, setMenus] = useState<Menu[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [pages, setPages] = useState<Post[]>([])
  const [isFooterMenu, setIsFooterMenu] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    getData()
  }, [visible])

  useEffect(() => {
    getMenus()
  }, [])

  const handleAddMenuCat = (values: any) => {
    //Add to menu or child Menu
    for (let key in values) {
      if (values[key] == true) {
        push(
          ref(db, 'menus/' + (menuId !== '' ? menuId + '/childrent/' : '')),
          {
            location: key.split('@')[0],
            id: key.split('@')[1],
            title: key.split('@')[2],
            url: key.split('@')[3],
            footer: isFooterMenu,
          },
        )
      }
    }

    setMenuId('')
    form.resetFields()
    setVisible(false)
  }

  const handleAddPageToMenu = (values: any) => {
    for (let key in values) {
      if (values[key] == true) {
        push(
          ref(db, 'menus/' + (menuId !== '' ? menuId + '/childrent/' : '')),
          {
            location: key.split('@')[0],
            id: key.split('@')[1],
            title: key.split('@')[2],
            url: key.split('@')[3],
            footer: isFooterMenu,
          },
        )
      }
    }

    setMenuId('')
    form.resetFields()
    setVisible(false)
  }

  const handleAddCustomLink = (values: any) => {
    const item = {
      location: 'href',
      id: '',
      title: values.title,
      url: values.url,
      footer: isFooterMenu,
    }

    push(
      ref(db, 'menus/' + (menuId !== '' ? menuId + '/childrent/' : '')),
      item,
    ).then(() => {
      console.log('Done')
    })

    setMenuId('')
    form.resetFields()
    setVisible(false)
  }

  const getData = () => {
    onValue(ref(db, '/'), (snap) => {
      if (snap.val()) {
        setData(snap.val())
      }

      const cats: Category[] = []

      for (let key in data?.categories) {
        cats.push({
          ...data?.categories[key],
          key: key,
        })
      }

      setCategories(cats)

      const itemsPage: Post[] = []
      for (let key in data?.pages) {
        itemsPage.push({
          ...data?.pages[key],
          key: key,
        })
      }

      setPages(itemsPage)
    })
  }

  const getMenus = () => {
    onValue(ref(db, 'menus/'), (snap) => {
      const items: Menu[] = []

      if (snap.val()) {
        snap.forEach((item) => {
          const childs = item.val().childrent

          const child: Menu[] = []
          for (let key in childs) {
            child.push({
              ...childs[key],
              key: key,
            })
          }

          items.push({
            ...item.val(),
            key: item.key,
            childrent: child,
          })
        })
        setMenus(items)
      }
    })
  }

  const removeMenu = (id: string) => {
    Modal.confirm({
      title: 'Xác nhận',
      content: 'Bạn chắc chắn muốn xóa menu item này?',
      onOk: () => {
        remove(ref(db, 'menus/' + id)).then(() => {
          console.log('Done')
        })
      },
    })
  }

  const addMenuchildrent = (key: string) => {
    setMenuId(key)
    setVisible(true)
  }

  const removeSubMenu = (key: string, id: string) => {
    Modal.confirm({
      title: 'Xác nhận',
      content: 'Bạn chắc chắn muốn xóa menu item này?',
      onOk: () => {
        remove(ref(db, 'menus/' + key + '/childrent/' + id)).then(() => {
          console.log('Done')
        })
      },
    })
  }

  return (
    <>
      <ContentHeader title="Menus" />
      <div className="container">
        <Card>
          <div className="row">
            <div
              className={visible ? 'col-md-4' : ''}
              style={{ display: visible ? 'block' : 'none' }}
            >
              <Card title="Thành phần" type="inner">
                <Tabs defaultActiveKey="1">
                  <TabPane key="1" tab="Chuyên mục">
                    <Form
                      layout="vertical"
                      form={form}
                      onFinish={handleAddMenuCat}
                    >
                      {categories.map((cat) => (
                        <Form.Item
                          key={cat.key}
                          valuePropName="checked"
                          initialValue={false}
                          name={
                            'cat@' +
                            cat.key +
                            '@' +
                            cat.title +
                            '@chuyen-muc/' +
                            cat.slug +
                            '/' +
                            cat.key
                          }
                        >
                          <Checkbox>{cat.title}</Checkbox>
                        </Form.Item>
                      ))}

                      <Form.Item>
                        <div className="text-right">
                          <Button htmlType="submit" type="primary">
                            Thêm
                          </Button>
                        </div>
                      </Form.Item>
                    </Form>
                  </TabPane>
                  <TabPane key="2" tab="Trang">
                    <Form form={form} onFinish={handleAddPageToMenu}>
                      {pages &&
                        pages.map((item) => (
                          <Form.Item
                            key={item.key}
                            valuePropName="checked"
                            initialValue={false}
                            name={
                              'page@' +
                              item.key +
                              '@' +
                              item.title +
                              '@trang/' +
                              item.slug
                            }
                          >
                            <Checkbox>{item.title}</Checkbox>
                          </Form.Item>
                        ))}

                      <div className="text-right">
                        {pages.length > 0 && (
                          <Button type="primary" htmlType="submit">
                            Thêm
                          </Button>
                        )}
                      </div>
                    </Form>
                  </TabPane>

                  <TabPane key="3" tab="Link tùy chỉnh">
                    <Form
                      form={form}
                      layout="vertical"
                      onFinish={handleAddCustomLink}
                    >
                      <Form.Item name="title" label="Tiêu đề">
                        <Input />
                      </Form.Item>

                      <Form.Item name="url" label="Liên kết">
                        <Input type="url" placeholder="https://google.com" />
                      </Form.Item>

                      <Form.Item>
                        <div className="text-right">
                          <Button type="primary" htmlType="submit">
                            Thêm
                          </Button>
                        </div>
                      </Form.Item>
                    </Form>
                  </TabPane>
                </Tabs>
              </Card>
            </div>
            <div className="col-md">
              <Tabs>
                <TabPane key="main-nenu" tab="Main menu">
                  <Card>
                    {menus.map((item) =>
                      !item.footer ? (
                        <div
                          key={item.key}
                          style={{ borderBottom: '1px solid #e9e9e9' }}
                        >
                          <div
                            className="row"
                            style={{
                              padding: '15px 0',
                            }}
                          >
                            <div className="col">
                              <p style={{ fontWeight: 'bold', margin: 0 }}>
                                {item.title}
                              </p>
                            </div>
                            <Space>
                              <button
                                onClick={() => addMenuchildrent(item.key)}
                                className="btn btn-sm btn-light"
                              >
                                <i className="fas fa-list"></i> Thêm menu con
                              </button>
                              <Popover content="Xóa menu">
                                <Button
                                  onClick={() => removeMenu(item.key)}
                                  type="text"
                                  icon={<i className="fas fa-trash-alt"></i>}
                                ></Button>
                              </Popover>
                            </Space>
                          </div>
                          {item.childrent &&
                            item.childrent.map((sub: Menu) => (
                              <div key={sub.key}>
                                <div
                                  className="row justify-center"
                                  style={{
                                    padding: '12px 20px',
                                  }}
                                >
                                  <Popover content="Xóa thành phần">
                                    <button
                                      onClick={() =>
                                        removeSubMenu(item.key, sub.key)
                                      }
                                      className="btn btn-sm btn-link"
                                      style={{ padding: 0 }}
                                    >
                                      <i className="fas fa-unlink text-muted"></i>
                                    </button>
                                  </Popover>

                                  <div className="col">
                                    <p style={{ margin: 0 }}>{sub.title}</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      ) : null,
                    )}

                    <button
                      style={{ marginTop: 30 }}
                      className="btn btn-sm btn-success"
                      onClick={() => {
                        setVisible(!visible)
                        setIsFooterMenu(false)
                      }}
                    >
                      Thêm item
                    </button>
                  </Card>
                </TabPane>

                <TabPane tab="Footer menu" key="footer-menu">
                  <Card>
                    {menus.map((item) =>
                      item.footer ? (
                        <div
                          key={item.key}
                          style={{ borderBottom: '1px solid #e9e9e9' }}
                        >
                          <div
                            className="row"
                            style={{
                              padding: '15px 0',
                            }}
                          >
                            <div className="col">
                              <p style={{ fontWeight: 'bold', margin: 0 }}>
                                {item.title}
                              </p>
                            </div>
                            <Space>
                              <Popover content="Xóa menu">
                                <Button
                                  onClick={() => removeMenu(item.key)}
                                  type="text"
                                  icon={<i className="fas fa-trash-alt"></i>}
                                ></Button>
                              </Popover>
                            </Space>
                          </div>
                          {item.childrent &&
                            item.childrent.map((sub: Menu) => (
                              <div key={sub.key}>
                                <div
                                  className="row justify-center"
                                  style={{
                                    padding: '12px 20px',
                                  }}
                                >
                                  <Popover content="Xóa thành phần">
                                    <button
                                      onClick={() =>
                                        removeSubMenu(item.key, sub.key)
                                      }
                                      className="btn btn-sm btn-link"
                                      style={{ padding: 0 }}
                                    >
                                      <i className="fas fa-unlink text-muted"></i>
                                    </button>
                                  </Popover>

                                  <div className="col">
                                    <p style={{ margin: 0 }}>{sub.title}</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      ) : null,
                    )}

                    <button
                      style={{ marginTop: 30 }}
                      className="btn btn-sm btn-success"
                      onClick={() => {
                        setVisible(!visible)
                        setIsFooterMenu(true)
                      }}
                    >
                      Thêm item
                    </button>
                  </Card>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}
