import { Button, Layout, Menu, Modal } from 'antd'
import MenuItem from 'antd/lib/menu/MenuItem'
import type { MenuProps } from 'antd'
import { routers } from '../routers/router'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { LocalData } from '../models'

const { Sider } = Layout

export const SiderComponent = () => {
  const [isCollap, setIsCollap] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)
  const [userData, setUserData] = useState<LocalData>()

  const navigate = useNavigate()
  //get info member

  useEffect(() => {
    getInfoMember()
  }, [])

  const getInfoMember = () => {
    const jsonData = localStorage.getItem('userData')
    jsonData && setUserData(JSON.parse(jsonData))
  }

  const items: MenuProps['items'] = []

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth)
    })

    width <= 1024 ? setIsCollap(true) : setIsCollap(false)

    return () => {
      window.removeEventListener('resize', () => {})
    }
  }, [width])

  const handleNavigate = (path: string, access: string) => {
    if (userData) {
      if (access == '' || userData.access.includes(access)) {
        navigate(path)
      } else {
        Modal.warning({
          title: 'Lỗi',
          content:
            'Xin lỗi, bạn không được quyền truy cập phần này, hãy liên hệ với quản trị viên nếu cần thêm thông tin.',
        })
      }
    } else {
      console.log('invalid member')
    }
  }

  routers.forEach((item) => {
    !item.hide &&
      items.push({
        label: (
          <Button
            type="text"
            style={{ width: '100%', textAlign: 'left', color: 'white' }}
            onClick={() => handleNavigate(item.path, item.access)}
          >
            {item.label}
          </Button>
        ),
        key: item.path,
        icon: item.icon,
      })
  })

  return (
    <Sider
      collapsed={isCollap}
      width={250}
      style={{
        marginLeft: width <= 425 ? -80 : 0,
        position: 'fixed',
        top: 0,
        bottom: 0,
        minHeight: '100vh',
      }}
    >
      {isCollap || (
        <Link to="/">
          <div
            className="text-center bg-danger"
            style={{ padding: '10px 0', height: 63 }}
          >
            <h2
              className="text-light text-uppercase"
              style={{ padding: 0, margin: 0 }}
            >
              Drview
            </h2>
          </div>
        </Link>
      )}

      <Menu theme="dark" mode="inline" items={items} />
    </Sider>
  )
}
