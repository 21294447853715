import { Avatar, Card, Form, Input, Tabs, Tag } from 'antd'
import { onValue, ref } from 'firebase/database'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ContentHeader } from '../components/ContentHeader'
import { HealthFiles } from '../components/health-files'
import { db } from '../firebase/firebaseConfig'
import { User } from '../models/user'
import { getDateString } from '../utils/change_date_to_string'
import { avatarDefault } from '../utils/default_image_link'
import { Schedules } from './Schedules'

const { TabPane } = Tabs
export const UserInfo = () => {
  const [user, setUser] = useState<User>()
  const { id } = useParams()
  useEffect(() => {
    getUserInfo()
  }, [id])

  const getUserInfo = () => {
    onValue(ref(db, 'users/' + id), (snap) => {
      if (snap.val()) {
        setUser({ ...snap.val(), key: snap.key })
      }
    })
  }

  console.log(user)
  return (
    <>
      <ContentHeader title="Thông tin người dùng" />
      <div className="container">
        <Card>
          <Card type="inner" title="Thông tin">
            <div className="row">
              <div className="col-md-4">
                <Avatar
                  style={{ width: 150, height: 150 }}
                  src={user?.photoURL ? user.photoURL : avatarDefault}
                />
              </div>
              <div className="col-md-8">
                <p>
                  Họ và Tên:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {user?.displayName}
                  </span>
                </p>
                <p>Email: {user?.email}</p>
                <p>Điện thoại: {user?.data?.phoneNumber}</p>
                <p>Giới tính: {user?.data?.gender}</p>
                <p>Địa chỉ: {user?.data?.address}</p>
                <p>
                  Ngày đăng ký:{' '}
                  {user?.metadata?.createdAt &&
                    getDateString(parseInt(user?.metadata?.createdAt))}
                </p>
                <p>
                  Trạng thái:
                  {user?.emailVerified ? (
                    <Tag style={{ marginLeft: 10 }} color="#87d068">
                      Đã xác minh
                    </Tag>
                  ) : (
                    <Tag style={{ marginLeft: 10 }} color="#f50">
                      Chưa xác minh
                    </Tag>
                  )}
                </p>
              </div>
            </div>
          </Card>

          <Card style={{ marginTop: 20 }}>
            <Tabs>
              <TabPane key="2" tab="Lịch hẹn">
                <Schedules target="user" />
              </TabPane>

              <TabPane key="3" tab="Hồ sơ sức khỏe">
                <HealthFiles />
              </TabPane>
            </Tabs>

            {/* Phần lịch hẹn */}
            {/* <Schedules target="user" /> */}
          </Card>
        </Card>
      </div>
    </>
  )
}
