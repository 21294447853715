import { useEffect, useState } from 'react'
import { ContentHeader } from '../components/Index'
import { AddCategory } from '../modals/Index'
import { db } from '../firebase/firebaseConfig'
import { onValue, ref, remove } from 'firebase/database'
import { Button, Card, List, message, Modal, Table } from 'antd'
import { styles } from '../styles'
import { Category } from '../models'

export const Categories = () => {
  const [isVisibleModalAddCategory, setIsVisibleModalAddCategory] = useState(
    false,
  )
  const [categories, setCategories] = useState<Category[]>([])
  const [category, setCategory] = useState<Category>()

  useEffect(() => {
    getCategories()
  }, [])

  const handleAddCategory = () => {
    setIsVisibleModalAddCategory(true)
  }

  const onCancel = () => {
    setIsVisibleModalAddCategory(false)
  }

  const getCategories = () => {
    onValue(ref(db, 'categories/'), (snap) => {
      const cats: Category[] = []
      if (snap.val()) {
        snap.forEach((item) => {
          cats.push({
            ...item.val(),
            key: item.key,
          })
        })
      } else {
        message.info('Chưa có chuyên mục nào cả')
      }

      setCategories(cats)
    })
  }

  const columns = [
    {
      title: 'Tên chuyên mục',
      dataIndex: 'title',
      key: 'title',
      render: (title: string) => <p style={{ fontWeight: 'bold' }}>{title}</p>,
    },
    {
      title: 'Bài viết',
      dataIndex: 'posts',
      key: 'posts',
      render: (num: number) => <div className="text-center">{num}</div>,
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '',
      dataIndex: '',
      key: 'btnContain',
      render: (item: Category) => (
        <div className="text-right">
          <Button
            type="text"
            icon={<i className="fas fa-edit text-muted"></i>}
            onClick={() => handleEditCategory(item)}
          />
          {
            //this line avoid user delete impotan category
            item.key !== '-N3yPtoepUVjNycH7WFH' &&
            item.key !== '-N3yWK0CcYyP2-eGxrK_' &&
            item.key !== '-N5146tibiCYyn8E0kuF' ? (
              <Button
                type="text"
                icon={<i className="fas fa-trash-alt text-danger"></i>}
                onClick={() => handleDeletCategory(item.key)}
              ></Button>
            ) : null
          }
        </div>
      ),
    },
  ]

  const handleDeletCategory = (id: string) => {
    Modal.confirm({
      title: 'Xác nhận',
      content: 'Bạn muốn xóa chuyên mục này?',
      onOk: () => {
        remove(ref(db, 'categories/' + id))
          .then(() => {
            message.success('Đã xóa chuyên mục')
          })
          .catch((error) => {
            console.log(error)
          })
      },
    })
  }

  const handleEditCategory = (cat: Category) => {
    setCategory(cat)
    setIsVisibleModalAddCategory(true)
  }
  return (
    <>
      <ContentHeader
        title="Chuyên mục"
        button={
          <button
            key="btnAddCategory"
            className="btn btn-sm btn-success"
            onClick={() => handleAddCategory()}
          >
            Thêm chuyên mục
          </button>
        }
      />
      <div className="container">
        <Card style={styles.card}>
          <Table dataSource={categories} columns={columns} />
        </Card>
        <AddCategory
          onCancel={onCancel}
          isVisible={isVisibleModalAddCategory}
          categories={categories}
          category={category}
        />
      </div>
    </>
  )
}
