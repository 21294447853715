import { Col, Layout, Row } from 'antd'
import { styles } from '../styles'
import { HeaderComponent } from './Index'
import { SiderComponent } from './SiderComponent'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import { routers } from '../routers/router'
import { useEffect, useState } from 'react'
import { LocalData } from '../models'
import { Dashboard } from '../pages/Dashboard'
import { MemberManagement } from '../pages/member_manager'
import { Schedules } from '../pages/Schedules'

const { Content } = Layout
function RouteComponent() {
  const [width, setWidth] = useState(window.innerWidth)
  const [userData, setUserData] = useState<LocalData>()

  useEffect(() => {
    const jsonData = localStorage.getItem('userData')

    jsonData && setUserData(JSON.parse(jsonData))
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth)
    })
    return () => {
      window.removeEventListener('resize', () => {})
    }
  }, [width])

  const showRouter = (item: string) => {
    if (item !== '') {
      if (userData?.access.includes(item)) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  return (
    <BrowserRouter>
      <Layout>
        <SiderComponent />

        <Layout
          style={{
            marginLeft: width >= 1024 ? 250 : width >= 425 ? 80 : 0,
          }}
        >
          <HeaderComponent />
          <Content style={{ padding: 20, minHeight: '100vh' }}>
            <Routes>
              {routers.map((item: any) =>
                showRouter(item.access) ? (
                  <Route
                    key={item.path}
                    path={item.path}
                    element={<item.element />}
                  ></Route>
                ) : null,
              )}
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </BrowserRouter>
  )
}

export default RouteComponent
