import { Comments } from '../pages/Comments'
import {
  Categories,
  Dashboard,
  Login,
  MemberManagement,
  Members,
  Menus,
  NewPost,
  Pages,
  Posts,
  Register,
  Schedules,
  Settings,
  SpecialMedical,
  Users,
} from '../pages/Index'
import { UserInfo } from '../pages/UserInfo'

export const routers = [
  {
    path: '/',
    element: Dashboard,
    label: 'Bảng điều khiển',
    icon: <i className="fas fa-tachometer-alt"></i>,
    hide: false,
    access: '',
  },
  {
    path: '/thanh-vien',
    element: Members,
    label: 'Thành viên',
    icon: <i className="fas fa-user-md"></i>,
    hide: false,
    access: 'members',
  },
  {
    path: '/khach-hang',
    element: Users,
    label: 'Khách hàng',
    icon: <i className="fas fa-users"></i>,
    hide: false,
    access: 'users',
  },
  {
    path: '/bai-moi/:path/:id',
    element: NewPost,
    label: 'Viết bài mới',
    icon: <i className="fas fa-pencil-alt"></i>,
    hide: true,
    access: 'posts',
  },
  {
    path: '/bai-viet',
    element: Posts,
    label: 'Bài viết',
    icon: <i className="fas fa-file"></i>,
    hide: false,
    access: 'posts',
  },
  {
    path: '/trang',
    label: 'Trang',
    icon: <i className="far fa-file"></i>,
    element: Pages,
    hide: false,
    access: 'pages',
  },
  {
    path: '/lich-hen',
    label: 'Lịch hẹn',
    icon: <i className="fas fa-file-medical"></i>,
    element: Schedules,
    hide: false,
    access: 'schedules',
  },
  {
    path: '/chuyen-muc',
    element: Categories,
    label: 'Chuyên mục',
    icon: <i className="fas fa-tags"></i>,
    hide: false,
    access: 'categories',
  },
  {
    path: '/dang-ky',
    element: Register,
    label: 'Đăng ký',
    icon: '',
    hide: true,
    access: '',
  },
  {
    path: '/dang-nhap',
    element: Login,
    label: 'Đăng nhập',
    icon: '',
    hide: true,
    access: '',
  },
  {
    path: '/thanh-vien/:name/:id',
    element: MemberManagement,
    icon: '',
    label: '',
    hide: true,
    access: '',
  },

  {
    path: 'chuyen-khoa',
    element: SpecialMedical,
    icon: <i className="fas fa-briefcase-medical"></i>,
    label: 'Chuyên khoa',
    hide: false,
    access: 'settings',
  },

  {
    path: 'binh-luan',
    element: Comments,
    icon: <i className="fas fa-comments"></i>,
    label: 'Bình luận',
    hide: false,
    access: 'comments',
  },

  {
    path: '/menu',
    element: Menus,
    label: 'Menu',
    icon: <i className="fas fa-bars"></i>,
    hide: false,
    access: 'settings',
  },
  {
    path: '/cai-dat',
    element: Settings,
    icon: <i className="fas fa-cogs"></i>,
    label: 'Cài đặt',
    hide: false,
    access: 'settings',
  },
  {
    path: '/trang-moi/:path/:id',
    element: NewPost,
    icon: '',
    label: 'Thêm trang mới',
    hide: true,
    access: 'pages',
  },
  {
    path: '/thong-tin-nguoi-dung/:id',
    element: UserInfo,
    icon: '',
    label: '',
    hide: '',
    access: '',
  },
]
