import { Form, Input, message, Modal } from 'antd'
import { createUserWithEmailAndPassword, signOut } from 'firebase/auth'
import { push, ref, set } from 'firebase/database'
import { useState } from 'react'
import { auth, db } from '../firebase/firebaseConfig'

interface Props {
  isVisible: boolean
  closeModal: () => void
}

export const AddUserModal = (props: Props) => {
  const [isShowButton, setIsShowButton] = useState(false)
  const { isVisible, closeModal } = props

  const [form] = Form.useForm()

  const onCancel = () => {
    form.resetFields()
    closeModal()
  }

  const handleAddUser = (values: any) => {
    createUserWithEmailAndPassword(auth, values.email, values.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user
        // ...

        updateUser(user)

        console.log('Done')

        setIsShowButton(true)
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        // ..
      })
  }

  const updateUser = (user: any) => {
    const dataUser = {
      displayName: user.email.split('@')[0],
      data: {
        gender: '',
        birthday: '',
        address: '',
        phoneNumber: '',
      },
      email: user.email,
      //Nếu đã là xác mình bởi admin hoặc tự xác minh thì không cập nhật lại nữa
      emailVerified: user.emailVerified === true ? true : user.emailVerified,
      lastLogin: user.metadata.lastLoginAt,
      uid: user.uid,
      metadata: user.metadata,
      photoURL: user.photoURL,
    }
    //save to data base
    set(ref(db, 'users/' + user.uid), dataUser)
      .then(() => {
        setIsShowButton(false)
        onCancel()
        message.success('Thêm người dùng thành công')
      })
      .then(() => {
        signOut(auth)
      })
  }

  return (
    <Modal
      onCancel={onCancel}
      title="Tạo người dùng"
      footer={null}
      visible={isVisible}
    >
      <Form form={form} layout="vertical" onFinish={handleAddUser}>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập email',
            },
          ]}
        >
          <Input
            prefix={
              <i
                className="fas fa-envelope text-muted"
                style={{ marginRight: 10 }}
              ></i>
            }
            size="large"
            placeholder="Địa chỉ email"
            type="email"
            maxLength={100}
            showCount
          />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập mật khẩu',
            },
          ]}
          name="password"
          label="Mật khẩu"
        >
          <Input
            minLength={6}
            maxLength={18}
            showCount
            placeholder="Mật khẩu"
            size="large"
            prefix={
              <i
                className="fas fa-lock text-muted"
                style={{ marginRight: 10 }}
              ></i>
            }
          />
        </Form.Item>

        <div
          className="text-center"
          style={{ marginTop: 50, marginBottom: 20 }}
        >
          <button
            disabled={isShowButton}
            className="btn btn-success"
            style={{
              minWidth: '80%',
              textTransform: 'uppercase',
              fontWeight: '700',
            }}
          >
            tạo tài khoản
          </button>
        </div>
      </Form>
    </Modal>
  )
}
