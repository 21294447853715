import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  message,
  Modal,
  Progress,
  DatePicker,
  Tabs,
} from 'antd'
import {
  equalTo,
  onValue,
  orderByKey,
  push,
  query,
  set,
  update,
} from 'firebase/database'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as database from 'firebase/database'
import TextArea from 'antd/lib/input/TextArea'
import { db, storage } from '../../firebase/firebaseConfig'
import { Member } from '../../models'
import { defaultImageLink } from '../../utils/default_image_link'
import { replacename } from '../../utils/replace_name'
import { acceptedImage } from '../../utils/accepted_image'
import { sha256 } from 'js-sha256'

const { Option } = Select
const refDb = database.ref
const localData = localStorage.getItem('userData')

export const MemberInfo = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [avatar, setAvatar] = useState('')
  const [progressUpload, setProgressUpload] = useState(0)
  const [loadding, setLoadding] = useState(false)
  const [isInfo, setIsInfo] = useState(false)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [validate, setValidate] = useState('')

  const { name, id } = useParams()

  useEffect(() => {
    const data = localData ? JSON.parse(localData) : ''

    if (data.access.includes('members')) {
      setValidate('members')
    }
  })

  useEffect(() => {
    if (name !== 'add-new') {
      onValue(refDb(db, 'members/' + id), (snap) => {
        if (snap.val()) {
          snap.val() && form.setFieldsValue(snap.val())
          snap.val().avatar !== '' && setAvatar(snap.val().avatar)
        }
      })

      if (name == 'info') {
        setIsInfo(true)
      } else {
        setIsInfo(false)
      }
    }
  }, [name, id])

  const handleAddMember = (values: any) => {
    //setLoadding(true)

    const data: Member = {
      alerts: [],
      userName: values.userName,
      phoneNumber: values.phoneNumber ?? '',
      regisDate: new Date().getTime(),
      displayName: values.displayName,
      birthday: values.birthDay ? values.birthDay : '',
      email: values.email ?? '',
      gender: values.gender ? values.gender : '',
      address: values.address ? values.address : '',
      workplace: values.workplace ? values.workplace : '',
      workplaceAddress: values.workplaceAddress ? values.workplaceAddress : '',
      title: values.title ? values.title : '',
      experient: values.experient ? values.experient : '',
      special: values.special ? values.special : '',
      role: values.role,
      cmnd: values.cmnd ? values.cmnd : '',
      ngaycap: values.ngaycap ? values.ngaycap : '',
      noicap: values.noicap ? values.noicap : '',
      description: values.description ?? '',
      avatar: avatar !== defaultImageLink ? avatar : '',
      access: [''],
      validate: validate,
    }

    if (validate == 'members') {
      if (name === 'add-new') {
        data.isActive = false
        data.password = sha256(values.password)

        set(refDb(db, 'members/' + values.userName), data).then(() => {
          message.success('Tạo thành viên thành công')
          navigate('/thanh-vien')
        })
      } else if (name === 'edit') {
        update(refDb(db, 'members/' + values.userName), data).then(() => {
          Modal.success({
            title: 'Thành công',
            content: 'Cập nhật thông tin thành viên thành công',
          })
          navigate('/thanh-vien')
        })
      }
    } else {
      Modal.warning({
        title: 'Thông báo',
        content: 'Bạn không đủ quyền để thực hiện chức năng này',
      })
    }
  }

  const uploadFile = (file: any) => {
    const name = replacename(file.name)

    const uploadTask = uploadBytesResumable(
      ref(storage, 'member/avatars/' + name),
      file,
    )
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setProgressUpload(progress)
      },
      (error) => {
        console.log(error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setAvatar(downloadURL)
        })
      },
    )
  }

  const handleUploadAvatar = (files: any) => {
    if (files) {
      if (acceptedImage.includes(files[0].type)) {
        uploadFile(files[0])
      } else {
        Modal.error({
          title: 'Lỗi',
          content: 'Bạn chỉ có thể chọn ảnh định dạng jpg, jpeg, png',
        })
      }
    }
  }

  const handleValidateUsername = (value: string) => {
    //kiểm tra tính hợp lệ và kiểm tra trùng lặp
    if (name == 'add-new') {
      if (value !== '') {
        onValue(refDb(db, 'members/' + value), (snap) => {
          if (snap.val()) {
            setIsDuplicate(true)
          } else {
            setIsDuplicate(false)
          }
        })
      }
    }
  }

  return (
    <Form
      onFinish={handleAddMember}
      layout={name == 'add-new' ? 'vertical' : 'horizontal'}
      form={form}
    >
      <div className="row">
        <div className="col">
          <Card title="Thông tin đăng nhập" type="inner">
            <div className="text-center">
              <label htmlFor={name == 'info' ? '' : 'inpAvatar'}>
                <img
                  src={avatar !== '' ? avatar : defaultImageLink}
                  style={{ width: 200, height: 200, objectFit: 'cover' }}
                />
                <p className="text-muted">Tải lên hình đại diện</p>
              </label>
            </div>
            <div className="d-none">
              <input
                id="inpAvatar"
                readOnly={isInfo}
                type="file"
                accept={acceptedImage.toString()}
                onChange={(e) => handleUploadAvatar(e.target.files)}
              />
            </div>
            {progressUpload > 0 && progressUpload < 100 ? (
              <Progress percent={progressUpload} status="active" />
            ) : null}

            <Form.Item
              name="userName"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: 'Không được để trống',
                },
              ]}
              label="Tên đăng nhập"
              validateStatus={isDuplicate ? 'error' : ''}
              help={isDuplicate ? 'Tài khoản đã tồn tại' : ''}
            >
              <Input
                showCount
                onChange={(e) => handleValidateUsername(e.target.value)}
                disabled={name == 'add-new' ? false : true}
                minLength={10}
                size="large"
                placeholder="Tên đăng nhập"
                type="text"
                autoComplete=""
              />
            </Form.Item>
            {name == 'add-new' ? (
              <Form.Item
                name="password"
                label="Mật khẩu"
                rules={[
                  {
                    required: true,
                    message: 'Mật khẩu phải lớn hơn 6 ký tự',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Mật khẩu"
                  size="large"
                  minLength={6}
                  maxLength={18}
                  showCount
                />
              </Form.Item>
            ) : null}

            <Form.Item name="role" initialValue="Thành viên" label="Chức vụ">
              <Select bordered={!isInfo} disabled={isInfo}>
                <Option value="Quản trị viên">Quản trị viên</Option>
                <Option value="Kiểm duyệt nội dung">Kiểm duyệt nội dung</Option>
                <Option value="Thư ký">Thư ký</Option>
                <Option value="Cộng tác viên">Cộng tác viên</Option>
                <Option value="Thành viên">Thành viên</Option>
              </Select>
            </Form.Item>
            <Form.Item name="description" label="Mô tả ngắn">
              <TextArea
                bordered={!isInfo}
                readOnly={isInfo}
                cols={10}
                rows={5}
                placeholder="Giới thiệu về thành viên, chuyên môn"
              />
            </Form.Item>
          </Card>
        </div>
        <div className="col">
          <Card title="Thông tin cá nhân" type="inner">
            <Form.Item
              name="displayName"
              label="Họ Và Tên"
              rules={[
                {
                  required: true,
                  message: 'Không được bỏ trống',
                },
              ]}
            >
              <Input
                bordered={!isInfo}
                readOnly={isInfo}
                placeholder=""
                style={{
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                }}
                size="large"
              />
            </Form.Item>
            <div className="row">
              <div className="col">
                <Form.Item name="birthDay" label="Ngày sinh: ">
                  <Input bordered={!isInfo} readOnly={isInfo} type="date" />
                </Form.Item>
              </div>
              <div className="col">
                <Form.Item name="gender" initialValue="Nam" label="Giới tính">
                  <Select bordered={!isInfo} disabled={isInfo}>
                    <Option value="Name">Nam</Option>
                    <Option value="Nữ">Nữ</Option>
                    <Option value="Khác">Khác</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Form.Item name="cmnd" label="CMND/CCCD">
                  <Input bordered={!isInfo} readOnly={isInfo} type="number" />
                </Form.Item>
              </div>
              <div className="col">
                <Form.Item name="ngaycap" label="Ngày cấp">
                  <Input bordered={!isInfo} readOnly={isInfo} type="date" />
                </Form.Item>
              </div>
            </div>
            <Form.Item label="Nơi cấp" name="noicap">
              <Input
                bordered={!isInfo}
                readOnly={isInfo}
                placeholder="Nơi cấp"
              />
            </Form.Item>
            <Form.Item name="phoneNumber" label="Điện thoại">
              <Input
                bordered={!isInfo}
                readOnly={isInfo}
                type="tel"
                placeholder="090..."
              />
            </Form.Item>
            <Form.Item name="email" label="Email">
              <Input
                bordered={!isInfo}
                readOnly={isInfo}
                type="email"
                placeholder="email"
              />
            </Form.Item>

            <Form.Item name="address" label="Địa chỉ thường trú">
              <Input
                bordered={!isInfo}
                readOnly={isInfo}
                placeholder="Địa chỉ thường trú"
                style={{ textTransform: 'capitalize' }}
              />
            </Form.Item>

            <Form.Item name="workplace" label="Nơi làm việc">
              <Input
                bordered={!isInfo}
                readOnly={isInfo}
                placeholder="Tên nơi đang làm việc"
              />
            </Form.Item>

            <Form.Item name="workplaceAddress" label="Địa chỉ nơi làm việc">
              <Input
                bordered={!isInfo}
                readOnly={isInfo}
                placeholder="Địa chỉ nơi đang làm việc"
              />
            </Form.Item>

            <div className="row">
              <div className="col">
                <Form.Item name="title" label="Chức danh">
                  <Input
                    bordered={!isInfo}
                    readOnly={isInfo}
                    placeholder="Học hàm, học vị, ... ví dụ BS. CKI"
                    style={{ textTransform: 'capitalize' }}
                  />
                </Form.Item>
              </div>
              <div className="col">
                <Form.Item name="experient" label="Số năm công tác">
                  <Input
                    bordered={!isInfo}
                    readOnly={isInfo}
                    type="number"
                    placeholder="5"
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item name="special" label="Lĩnh vực chuyên môn">
              <Input
                bordered={!isInfo}
                readOnly={isInfo}
                placeholder="Nội thần kinh, Ngoại chấn thương...."
                style={{ textTransform: 'capitalize' }}
              />
            </Form.Item>
          </Card>
        </div>
      </div>
      {name === 'info' ? null : (
        <Card bordered={false} style={{ marginTop: 20 }}>
          <div className="text-right">
            <Space>
              <button className="btn btn-sm btn-link">
                <i className="fas fa-redo"></i> Làm lại
              </button>
              <Link
                className="btn btn-sm btn-light"
                to="/thanh-vien"
                type="ghost"
              >
                Hủy bỏ
              </Link>
              <Button
                loading={loadding}
                type="primary"
                onClick={() => form.submit()}
                style={{ padding: '5px 30px' }}
                className="btn btn-sm btn-success"
              >
                {name === 'add-new' ? 'Đồng ý' : 'Cập nhật'}
              </Button>
            </Space>
          </div>
        </Card>
      )}
    </Form>
  )
}
