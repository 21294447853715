import { Button, Card, Form, Input, Layout, message, Row } from 'antd'
import { onValue, ref } from 'firebase/database'
import { sha256 } from 'js-sha256'
import { db } from '../firebase/firebaseConfig'

export const Login = () => {
  const [form] = Form.useForm()

  const handleLogin = (values: any) => {
    onValue(ref(db, 'members/' + values.username), (snap) => {
      if (snap.val()) {
        if (sha256(values.pass) === snap.val().password) {
          const info = {
            isLogin: true,
            username: values.username,
            role: snap.val().role,
            access: snap.val().access ?? '',
          }

          window.localStorage.setItem('userData', JSON.stringify(info))
          message.success('Đăng nhập thành công')
          window.location.reload()
        } else {
          message.warning('Tài khoản hoặc mật khẩu không đúng')
        }
      } else {
        message.warning('Tài khoản hoặc mật khẩu không chính xác')
      }
    })
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <div className="container-fluid">
        <div className="col-md-4 offset-md-4">
          <Card style={{ marginTop: 50 }}>
            <Form form={form} layout="vertical" onFinish={handleLogin}>
              <div className="text-center">
                <h2 className="text-uppercase font-weight-bold">Đăng nhập</h2>
              </div>

              <Form.Item
                name="username"
                label="Tên đăng nhập"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Tên đăng nhập"
                  prefix={
                    <i
                      style={{ paddingRight: 10 }}
                      className="fa fa-phone-alt"
                    ></i>
                  }
                />
              </Form.Item>
              <Form.Item
                name="pass"
                label="Mật khẩu"
                rules={[
                  {
                    required: true,
                    message: 'Nhập mật khẩu',
                  },
                ]}
              >
                <Input
                  showCount
                  size="large"
                  placeholder="Mật khẩu"
                  minLength={6}
                  prefix={
                    <i style={{ paddingRight: 10 }} className="fas fa-lock"></i>
                  }
                  type="password"
                />
              </Form.Item>

              <p>
                <i className="text-muted">
                  Liên hệ với quản trị viên nếu bạn quên mật khẩu
                </i>
              </p>
              <Form.Item>
                <div className="text-center" style={{ marginTop: 20 }}>
                  <button
                    onClick={() => form.submit()}
                    className="btn btn-sm btn-success submit"
                    style={{ width: '50%', height: 40, fontWeight: 'bold' }}
                  >
                    ĐĂNG NHẬP
                  </button>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </Layout>
  )
}
