import {
  Button,
  Card,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Popover,
  Space,
  Upload,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { RcFile } from 'antd/lib/upload'
import { UploadFile, UploadProps } from 'antd/lib/upload/interface'
import { onValue, ref, update } from 'firebase/database'
import { getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { useEffect, useState } from 'react'
import { db, storage } from '../firebase/firebaseConfig'
import { Schedule, User } from '../models'
import { acceptedImage } from '../utils/accepted_image'
import { changeFormatDate } from '../utils/change_format_date'
import * as sto from 'firebase/storage'
import { replacename } from '../utils/replace_name'
import { Link } from 'react-router-dom'

interface Props {
  getSchedules: () => void
  closeModal: () => void
  schedule?: Schedule
  visible: boolean
}

const refStorage = sto.ref

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export const PrescriptionModal = (props: Props) => {
  const { visible, closeModal, schedule, getSchedules } = props
  const [patient, setPatient] = useState<User>()
  const [diagnostics, setDiagnostics] = useState<string[]>([])
  const [images, setImages] = useState<string[]>([])
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [loaddingUpload, setLoaddingUpload] = useState(0)
  const [loadding, setLoadding] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    getPatient()
    getDiagnostics()
  }, [schedule, visible])

  const getPatient = () => {
    onValue(ref(db, 'users/' + schedule?.idPatient), (snap) => {
      snap.val() && setPatient(snap.val())
    })
  }

  const onCloseModal = () => {
    form.resetFields()
    setFileList([])
    setImages([])
    closeModal()
  }

  const getDiagnostics = () => {
    const items: string[] = []

    onValue(ref(db, 'schedules/'), (snap) => {
      snap.val() &&
        snap.forEach((item) => {
          const dia = item.val().chandoan
          if (!diagnostics.includes(dia)) {
            items.push(dia)
          }

          setDiagnostics(items)
        })
    })
  }

  const handleImage = (file: any) => {
    const name = replacename(file.name)
    const uploadTask = uploadBytesResumable(
      refStorage(storage, 'images/' + name),
      file,
    )
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        setLoaddingUpload(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
      },
      (error) => {
        console.log(error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          images.push(downloadURL)
        })
      },
    )
  }

  const onFinish = (values: any) => {
    //setLoadding(true)
    //hoàn thành phiên khám
    //upload hình ảnh và lấy link download
    // if (fileList.length > 0) {
    //   fileList.forEach((itemImg) => {
    //     handleImage(itemImg.originFileObj)
    //   })
    // }

    // //console.log(values, data)
    update(ref(db, 'schedules/' + schedule?.key), {
      chandoan: values.chandoan ?? '',
      dienbien: values.dienbien ?? '',
      state: 'completed',
    }).then(() => {
      setLoadding(false)

      Modal.success({
        title: 'Hoàn thành',
        content: 'Cám ơn bạn đã hoàn thành phiên khám',
      })

      closeModal()
      getSchedules()
    })
  }

  const handleCancel = () => setPreviewVisible(false)

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewVisible(true)
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
    )
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList)

  const uploadButton = (
    <div>
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  return (
    <Modal visible={visible} width={1000} onCancel={onCloseModal} footer={null}>
      <div className="col">
        <Divider orientation="left">Thông tin bệnh nhân</Divider>
        <div className="row">
          <div className="col col-md">
            Họ và Tên:{' '}
            <Popover content="Xem thông tin bệnh nhân">
              <Link
                to={'/thong-tin-nguoi-dung/' + patient?.uid}
                style={{ textTransform: 'capitalize' }}
              >
                {patient?.displayName}
              </Link>
            </Popover>
          </div>
          <div className="col col-md">
            <p style={{ textTransform: 'capitalize' }}>
              Giới tính: {patient?.data?.gender}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col col-md">
            <p style={{ textTransform: 'capitalize' }}>
              Ngày sinh:{' '}
              {patient?.data?.birthday &&
                changeFormatDate(patient?.data?.birthday)}
            </p>
          </div>
          <div className="col col-md">
            <p style={{ textTransform: 'capitalize' }}>
              Điện thoại: {patient?.data?.phoneNumber}
            </p>
          </div>
        </div>

        <p style={{ textTransform: 'capitalize' }}>
          Địa chỉ: {patient?.data?.address}
        </p>
      </div>
      {schedule?.content && (
        <>
          <Divider orientation="left">Triệu chứng</Divider>
          <p>{schedule?.content}</p>
        </>
      )}

      {schedule?.note && (
        <>
          <Divider orientation="left">Ghi chú</Divider>
          <p>{schedule?.note}</p>
        </>
      )}

      {schedule?.images && (
        <>
          <Divider orientation="left">Hình ảnh</Divider>
          <Image.PreviewGroup>
            {schedule.images.map((item) => (
              <Image
                key={schedule.images && schedule.images.indexOf(item)}
                width={200}
                src={item}
              />
            ))}
          </Image.PreviewGroup>
        </>
      )}

      {/* <Divider orientation="left">Hình ảnh</Divider>
      <Upload
        accept={acceptedImage.toString()}
        action=""
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={() => false}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal> */}
      <Card
        type="inner"
        size="small"
        style={{ margin: '20px 0' }}
        title="Khám bệnh"
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item name="dienbien" label="Diễn biến">
            <TextArea
              cols={10}
              rows={5}
              placeholder="Diễn biến của bệnh trước khi đến khám và hiện tại"
            />
          </Form.Item>
          <Form.Item name="chandoan" label="Chẩn đoán bệnh">
            <Input
              placeholder="Viêm phế quản"
              id="chandoan"
              list="diagnostics"
            />
          </Form.Item>
          <datalist id="diagnostics">
            {diagnostics && diagnostics.map((item) => <option value={item} />)}
          </datalist>
        </Form>
        <div className="text-right">
          <Space>
            <button
              onClick={onCloseModal}
              className="btn btn-sm btn-outline-secondary"
            >
              Hủy bỏ
            </button>
            <Button
              type="primary"
              loading={loadding}
              onClick={() => form.submit()}
              className="btn btn-sm btn-primary"
            >
              Hoàn thành
            </Button>
          </Space>
        </div>
      </Card>
    </Modal>
  )
}
