import { render } from '@testing-library/react'
import { Card, List, Tabs, Tag } from 'antd'
import {
  limitToLast,
  onValue,
  orderByChild,
  query,
  ref,
} from 'firebase/database'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../firebase/firebaseConfig'
import { Post, Schedule, User } from '../models'
import { changeFormatDate } from '../utils/change_format_date'

const { TabPane } = Tabs

export const Dashboard = () => {
  const [schedules, setSchedules] = useState<Schedule[]>([])
  const [posts, setPosts] = useState<Post[]>([])
  const [patients, setPatients] = useState<User[]>([])

  useEffect(() => {
    getSchedules()
    // getPosts()
    getPatients()
  }, [])

  const changeTagName = (stateStr: string, target: string) => {
    let color = ''
    let state = ''

    switch (stateStr) {
      case 'outOfDate':
        color = 'default'
        state = 'Quá hạn'
        break
      case 'completed':
        color = '#2ecc71'
        state = 'Hoàn thành'
        break
      case 'canceled':
        color = '#f50'
        state = 'Đã hủy'
        break
      default:
        color = '#f39c12'
        state = 'Chờ khám'
        break
    }

    return target === 'name' ? state : color
  }

  const getPatients = () => {
    const items: User[] = []

    onValue(ref(db, 'users/'), (snap) => {
      snap.val() &&
        snap.forEach((item) => {
          items.push({
            ...item.val(),
            uid: item.key,
          })
        })
      setPatients(items)
    })
  }

  const getPatientName = (id: string) => {
    let name = ''

    patients.forEach((item) => {
      if (item.uid === id && item.displayName) {
        name = item.displayName
      }
    })

    return name
  }

  const getSchedules = () => {
    const filterSchedule = query(ref(db, 'schedules/'), limitToLast(5))
    const items: Schedule[] = []
    onValue(filterSchedule, (snap) => {
      if (snap.val()) {
        snap.forEach((item) => {
          items.push({
            ...item.val(),
            key: item.key,
          })
        })
        setSchedules(items)
      }
    })
  }

  const getPosts = (filter: string) => {
    const items: Post[] = []
    let list
    const filterPosts = query(
      ref(db, 'posts/'),
      orderByChild(filter),
      limitToLast(5),
    )
    onValue(filterPosts, (snap) => {
      if (snap.val()) {
        snap.forEach((item) => {
          if (item.val().state === 'Xuất bản') {
            items.unshift({
              ...item.val(),
              key: item.key,
            })
          }
        })
      }
    })

    list = (
      <List>
        {items.map((item) => (
          <List.Item
            key={item.key}
            extra={
              filter !== 'publishDate' ? (
                <p>
                  <i className="fas fa-eye text-muted"></i> {item.view}
                </p>
              ) : null
            }
          >
            <List.Item.Meta title={item.title} />
          </List.Item>
        ))}
      </List>
    )

    return list
  }

  return (
    <div className="container-fluid">
      <Card>
        <div className="row">
          <div className="col col-md-6">
            <Card
              className="card-dashboard"
              type="inner"
              title="Lịch hẹn"
              extra={[
                <Link key="lich-hen" to="/lich-hen">
                  Xem thêm...
                </Link>,
              ]}
            >
              <List>
                {schedules.map((item) => (
                  <List.Item
                    key={item.key}
                    actions={[
                      <Tag
                        key={item.key}
                        color={changeTagName(item.state, 'color')}
                      >
                        {changeTagName(item.state, 'name')}
                      </Tag>,
                    ]}
                  >
                    <List.Item.Meta
                      title={'Bệnh nhân: ' + getPatientName(item.idPatient)}
                      description={changeFormatDate(item.attime)}
                    />
                  </List.Item>
                ))}
              </List>
            </Card>
          </div>
          <div className="col col-md-6">
            <Card
              type="inner"
              title="Bài viết"
              extra={[
                <Link key="link" to="/bai-viet">
                  Xem thêm
                </Link>,
              ]}
            >
              <Tabs>
                <TabPane key={1} tab="Mới nhất">
                  {getPosts('publishDate')}
                </TabPane>
                <TabPane key={2} tab="Xem nhiều">
                  {getPosts('view')}
                </TabPane>
                <TabPane key={3} tab="Yêu thích">
                  {getPosts('like')}
                </TabPane>
              </Tabs>
            </Card>
          </div>
        </div>
      </Card>
    </div>
  )
}
