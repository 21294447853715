import { Button, Card, Table, Space, Modal, message, Badge } from 'antd'
import { onAuthStateChanged, sendEmailVerification } from 'firebase/auth'
import {
  equalTo,
  onValue,
  orderByChild,
  query,
  ref,
  remove,
} from 'firebase/database'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { auth, db } from '../firebase/firebaseConfig'
import { ShowImagesModal } from '../modals/show_images'
import { HealthFile } from '../models/health_files'
import { changeFormatDate } from '../utils/change_format_date'

export const HealthFiles = () => {
  const [images, setImages] = useState<string[]>([])
  const [healthFiles, setHealthFiles] = useState<HealthFile[]>([])
  const [isVisibleModalUpload, setIsVisibleModalUpload] = useState(false)
  const [isVisibleModalImages, setIsVisibleModalImages] = useState(false)

  //get //Lấy ra những lần khám bệnh đã hoàn thành của bệnh nhân này

  const { id } = useParams()

  useEffect(() => {
    getHealthFiles()
  }, [id, isVisibleModalUpload])

  const getHealthFiles = () => {
    const items: HealthFile[] = []

    const fillter = query(
      ref(db, 'healthFiles/'),
      orderByChild('idPatient'),
      equalTo(id as string),
    )

    onValue(fillter, (snap) => {
      snap.val() &&
        snap.forEach((item) => {
          items.push({
            ...item.val(),
            key: item.key,
          })
        })

      setHealthFiles(items)
    })
  }

  /*
    - tạo hồ sơ sức khỏe gồm các thông tin như ngày khám, người khám, chẩn đoán, hình ảnh và các file liên quan
    như đơn thuốc, xét nghiệm hoặc file pdf

    - Chỉ những người dùng đã xác minh tài khoản mới được tạo hồ sơ sức khỏe
    - Vào được đến đây chắc chắn là người dùng đã đăng nhập
  */
  const handleAddHealthFile = () => {
    //kiểm tra trạng thái xác minh tài khoản
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.emailVerified) {
          //console.log('Đã xác minh')
          //Gọi modal tạo hồ sơ sức khỏe
          setIsVisibleModalUpload(true)
        } else {
          Modal.warning({
            title: 'Lỗi',
            content:
              'Bạn cần xác minh địa chỉ email trước khi có thể tạo hồ sơ sức khỏe, bạn có muốn chúng tôi gửi lại email xác minh tài khoản không?',
            onOk: () => {
              //Gửi lại email xác minh tài khoản
              sendEmailVerification(user).then(() => {
                Modal.success({
                  title: 'Thành công',
                  content:
                    'Chúng tôi đã gửi 1 email có chứa đường link kích hoạt đến hộp thư của bạn, vui lòng kiểm tra và làm theo hướng dẫn để kích hoạt tài khoản.',
                })
              })
            },
          })
        }
      }
    })
  }

  /*
    Xóa hồ sơ sức khỏe
  */
  const handleDeleteHeathFile = (key?: string) => {
    Modal.confirm({
      title: 'Xóa hồ sơ',
      content:
        'Xóa hồ sơ sức khỏe sẽ không khôi phục được, bạn có muốn tiếp tục không?',
      onOk: () => {
        //remove health file
        remove(ref(db, 'healthFiles/' + key))
          .then(() => {
            message.success('Đã xóa hồ sơ sức khỏe')
            getHealthFiles()
          })
          .catch((e) => message.error(e))
      },
    })
  }

  /*
    hiển thị modal images và truyền hình ảnh qua
    giúp người dùng xem được hình ảnh của hồ sơ
  */

  const showModalImages = (items?: string[]) => {
    items && setImages(items)
    setIsVisibleModalImages(true)
  }

  const closeModalUpload = () => {
    setIsVisibleModalUpload(false)
  }

  const closeModalImages = () => {
    setImages([])
    setIsVisibleModalImages(false)
  }

  const colunms = [
    {
      title: 'Ngày khám',
      dataIndex: 'attime',
      key: 'attime',
      render: (item: string) => changeFormatDate(item),
    },

    {
      title: 'Bác sĩ',
      dataIndex: 'idDoctor',
      key: 'doctor',
      render: (name: string) => (
        <label style={{ textTransform: 'capitalize' }}>{name}</label>
      ),
    },
    {
      title: 'Nơi khám',
      dataIndex: 'noikham',
      key: 'noikham',
      render: (name: string) => <label className="text-one-line">{name}</label>,
      width: '30%',
    },
    {
      title: 'Chẩn đoán',
      dataIndex: 'chandoan',
      key: 'chandoan',
    },

    {
      title: '',
      dataIndex: '',
      key: 'buttonContain',
      render: (item: HealthFile) => (
        <div className="text-center">
          <Space>
            {/* Những hồ sơ nào có hình ảnh, hiện nút xem hình ảnh */}
            {item.images && item.images?.length > 0 ? (
              <Badge count={item.images.length} size="small">
                <Button
                  onClick={() => showModalImages(item?.images)}
                  type="text"
                  icon={<i className="fas fa-paperclip"></i>}
                />
              </Badge>
            ) : null}
            <Button
              onClick={() => handleDeleteHeathFile(item.key)}
              key="btn-remove"
              type="text"
              icon={<i className="fas fa-trash text-muted"></i>}
            />
          </Space>
        </div>
      ),
    },
  ]

  return (
    <>
      <Card
        title="Hồ sơ sức khỏe"
        size="small"
        className="card-inner"
        style={{ marginBottom: 20 }}
      >
        {/* show schedules not complete */}
        {healthFiles.length > 0 ? (
          <Table
            dataSource={healthFiles}
            key={''}
            columns={colunms}
            size="small"
          />
        ) : (
          <div className="text-center">
            <p className="text-muted">Chưa có hồ sơ sức khỏe</p>
          </div>
        )}
      </Card>

      <ShowImagesModal
        isVisible={isVisibleModalImages}
        closeModal={closeModalImages}
        images={images}
      />
    </>
  )
}
