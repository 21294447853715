import { Tabs } from 'antd'

import { useEffect, useState } from 'react'
import { ContentHeader } from '../components/ContentHeader'
import { db } from '../firebase/firebaseConfig'
import { onValue, ref } from 'firebase/database'
import { SettingInfo } from '../components/SettingInfo'
import { SettingSocial, SettingYoutubeVideos } from '../components/Index'
import { Setting } from '../models'

const { TabPane } = Tabs

export const Settings = () => {
  const [settings, setSettings] = useState<Setting>()

  useEffect(() => {
    getSettings()
  }, [])

  const getSettings = () => {
    onValue(ref(db, 'settings/'), (snap) => {
      if (snap.val()) {
        setSettings(snap.val())
      }
    })
  }

  return (
    <>
      <ContentHeader title="Cài đặt" />
      <div className="container">
        <Tabs defaultActiveKey="1">
          <TabPane key="1" tab="Thông tin">
            <SettingInfo setting={settings} getSetting={getSettings} />
          </TabPane>
          <TabPane tab="Kết nối" key="2">
            <SettingSocial setting={settings} getSetting={getSettings} />
          </TabPane>
          <TabPane tab="Videos Youtube" key="3">
            <SettingYoutubeVideos setting={settings} getSetting={getSettings} />
          </TabPane>
        </Tabs>
      </div>
    </>
  )
}
