import { onValue, ref } from 'firebase/database'
import { db } from '../firebase/firebaseConfig'

export const getPatientName = (key: string) => {
  let name

  onValue(ref(db, 'users/' + key), (snap) => {
    if (snap.val()) {
      name = snap.val().displayName
    }
  })
  return name
}
