/*
  Modal nay hiển thị hình ảnh khi người dùng bấm vào nút xem hình,
  hình ảnh truyền trong props
*/

import { Button, Modal, Image } from 'antd'

interface Props {
  images?: string[]
  isVisible: boolean
  closeModal: () => void
}

export const ShowImagesModal = (props: Props) => {
  const { images, closeModal, isVisible } = props

  const onCancel = () => {
    closeModal()
  }

  return (
    <Modal
      onCancel={onCancel}
      visible={isVisible}
      footer={[
        <Button onClick={onCancel} key="btn-close" type="primary">
          OK
        </Button>,
      ]}
    >
      {/* show image review */}
      <Image.PreviewGroup>
        {images?.map((item) => (
          <Image width={200} src={item} style={{ margin: 10 }} />
        ))}
      </Image.PreviewGroup>
    </Modal>
  )
}
