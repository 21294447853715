import {
  Badge,
  Button,
  Card,
  Input,
  message,
  Modal,
  Popover,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd'
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth'
import {
  equalTo,
  onValue,
  orderByChild,
  query,
  ref,
  remove,
  update,
} from 'firebase/database'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ContentHeader } from '../components/ContentHeader'
import { auth, db } from '../firebase/firebaseConfig'
import { AddUserModal } from '../modals/addUser'
import { Schedule } from '../models'
import { HealthFile } from '../models/health_files'
import { User } from '../models/user'
import { getDateString } from '../utils/change_date_to_string'
import { changeFormatDate } from '../utils/change_format_date'
import { replaceSearchKey } from '../utils/replaceSearchKey'

const { Search } = Input

export const Users = () => {
  const [users, setUsers] = useState<User[]>([])
  const [showInfo, setShowInfo] = useState(false)
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [schedules, setSchedules] = useState<Schedule[]>([])
  const [healthFiles, setHealthFiles] = useState<HealthFile[]>([])

  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    getUsers()
  }, [])

  //get users
  const getUsers = (key?: string) => {
    onValue(ref(db, 'users/'), (snap) => {
      if (snap.val()) {
        let items: User[] = []
        snap.forEach((item) => {
          if (key && key !== '') {
            if (item.val().email.includes(replaceSearchKey(key))) {
              items.push({
                ...item.val(),
                uid: item.key,
              })
            }
          } else {
            items.push({
              ...item.val(),
              uid: item.key,
            })
          }
        })

        setUsers(items)
      }
    })
  }

  const sortSchedulesByUid = (uid: string) => {
    let schedulesCount = 0

    schedules.forEach((item) => {
      console.log(item.idPatient)

      if (item.idPatient === uid) {
        console.log(item.idPatient, uid)
        schedulesCount += 1
      }

      console.log(schedulesCount)
    })

    return schedulesCount
  }

  const closeModal = () => {
    setIsVisibleModal(false)
  }

  const removeUser = (key: string) => {
    Modal.confirm({
      title: 'Xóa người dùng',
      content:
        'Bạn muốn xóa người dùng khỏi hệ thống, điều này cũng sẽ xóa toàn bộ thông tin liên quan và không thể khôi phục được',
      onOk: () => {
        remove(ref(db, 'users/' + key)).then(() => {
          message.success('Remove user successfuly')
        })
      },
    })
  }

  //Xác minh người dùng
  const verifiedUser = (uid: string) => {
    //update info user in database

    update(ref(db, 'users/' + uid), {
      emailVerified: true,
    }).then(() => {
      message.success('Đã xác minh người dùng')
    })
  }

  const getSchedules = () => {
    const items: Schedule[] = []

    onValue(ref(db, 'schedules/'), (snap) => {
      snap.val() &&
        snap.forEach((item) => {
          items.push({
            ...item.val(),
            key: item.key,
          })
        })

      setSchedules(items)
    })
  }

  const columns = [
    {
      title: 'Họ và Tên',
      dataIndex: '',
      key: 'displayName',
      render: (item: User) => (
        <>
          <Link to={'/thong-tin-nguoi-dung/' + item.uid}>
            <p
              style={{
                margin: 0,
              }}
            >
              {item.displayName}
            </p>
          </Link>

          {item.emailVerified ? (
            <Tag color="green">Đã xác minh</Tag>
          ) : (
            <Space>
              <Tag>Chưa xác minh</Tag>
              <Popover content="Xác minh người dùng">
                <Button
                  onClick={() => verifiedUser(item.uid)}
                  type="text"
                  icon={<i className="fas fa-user-check text-success"></i>}
                />
              </Popover>
            </Space>
          )}
        </>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      render: (address: string) => (
        <p style={{ margin: 0 }} className="text-description-one-line">
          {address}
        </p>
      ),
    },

    {
      title: 'Đăng ký',
      dataIndex: '',
      key: 'createAt',
      render: (item: User) => (
        <p className="text-description-one-line">
          {item.metadata?.createdAt &&
            getDateString(parseInt(item.metadata?.createdAt as string))}
        </p>
      ),
    },

    {
      title: 'Đăng nhập lần cuối',
      dataIndex: '',
      key: 'lastLoginAt',
      render: (item: User) => (
        <p className="text-description-one-line">
          {item.metadata?.lastLoginAt &&
            getDateString(parseInt(item.metadata?.lastLoginAt as string))}
        </p>
      ),
    },

    {
      title: '',
      dataIndex: 'key',
      key: 'btnContainer',
      render: (key: string) => (
        <div className="col text-right">
          <Space>
            <Popover content="Xóa người dùng">
              <Button
                onClick={() => removeUser(key)}
                type="text"
                icon={<i className="fas fa-user-alt-slash text-muted"></i>}
              />
            </Popover>
          </Space>
        </div>
      ),
    },
  ]

  const handleAddUser = () => {
    setIsVisibleModal(true)
  }

  return (
    <>
      <ContentHeader title="Khách hàng" />
      <div className="container-fluid">
        <Card
          title={
            <div className="row">
              <div className="col">
                <Search
                  placeholder="Email hoặc tên người dùng"
                  size="large"
                  allowClear
                  onSearch={(val) => getUsers(val)}
                />
              </div>
              <div className="col text-right">
                <button
                  onClick={handleAddUser}
                  className="btn btn-sm btn-success"
                >
                  <i className="fas fa-user-plus"></i> Tạo người dùng
                </button>
              </div>
            </div>
          }
        >
          <Table dataSource={users} columns={columns} />
        </Card>
      </div>
      <AddUserModal isVisible={isVisibleModal} closeModal={closeModal} />
    </>
  )
}
