import { Button, Card, Form, Input, message } from 'antd'
import { ref, update } from 'firebase/database'
import { useEffect } from 'react'
import { db } from '../firebase/firebaseConfig'
import { Setting } from '../models'

interface Props {
  setting: Setting | undefined
  getSetting: () => void
}

export const SettingSocial = (props: Props) => {
  const [form] = Form.useForm()
  const { setting, getSetting } = props

  const handleUpdateSocialList = (values: any) => {
    update(ref(db, 'settings/socials/'), values).then(() => {
      message.success('Cập nhật mạng xã hội thành công')
    })
  }

  useEffect(() => {
    if (setting) {
      form.setFieldsValue(setting.socials)
    }
  }, [])

  return (
    <div className="col-md-8 offset-md-2">
      <Card>
        <p>
          <i className="text-muted">Danh sách mạng xã hội</i>
        </p>

        <Form form={form} layout="vertical" onFinish={handleUpdateSocialList}>
          <Form.Item name="facebook" label="Facebook">
            <Input />
          </Form.Item>
          <Form.Item name="youtube" label="Youtube">
            <Input />
          </Form.Item>
          <Form.Item name="twitter" label="Twitter">
            <Input />
          </Form.Item>

          <Form.Item name="instagram " label="Instagram ">
            <Input />
          </Form.Item>
          <Form.Item name="linkedin  " label="Linkedin ">
            <Input />
          </Form.Item>
          <Form.Item name="pinterest " label="Pinterest ">
            <Input />
          </Form.Item>
          <Form.Item name="wechat " label="Wechat ">
            <Input />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" type="primary">
              Cập nhật
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}
