import './App.css'
import { RouteComponent } from './components/Index'
import { Login } from './pages/Index'

function App() {
  let info: any = window.localStorage.getItem('userData')

  return info ? <RouteComponent /> : <Login />
}

export default App
